.free {
  color: #505050;
  display: inline-block;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  position: relative;
}
.freeImg {
  bottom: 0;
  font-size: 32px;
  left: 0;
  position: absolute;
  z-index: 0;
}
