.expeditedShippingContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.img {
  min-width: 24px;
}
.text {
  margin-left: 5px;
  font-size: 16px;
  color: #0E0E0E;
  line-height: 18px;
}
.text strong {
  font-weight: 600;
  white-space: nowrap;
}
.link {
  margin-left: 5px;
}
