:global .modal-body {
  padding-left: 30px;
}
.container {
  width: auto;
}
.title {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.text {
  color: #505050;
  font-size: 16px;
  line-height: 24px;
}
.buttonGroup button {
  margin-left: 10px;
}
