/* stylelint-disable function-calc-no-invalid */
.modalContainer {
  min-width: 100%;
  overflow: hidden;
}
.modalTitle {
  color: #21201f;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 8px;
  margin-top: 16px;
  text-align: center;
}
.modalPicture {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
}
.modalDescription {
  color: #505050;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 20px;
}
.modalAction {
  padding: 0 20px 20px 20px;
  text-align: right;
}
.btnImage {
  align-items: center;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  color: #505050;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  justify-content: center;
  line-height: 20px;
  padding: 12px;
  text-align: center;
}
.btnImage:first-of-type {
  margin-right: 16px;
}
.btnImage:hover {
  background-color: #fcfcfc;
}
.btnImageActive {
  background-color: #ebf4f9;
  border: 2px solid #0075ae;
  color: #21201f;
}
.btnImageActive:hover {
  background-color: #e1ebf1;
}
.btnImageLabel {
  font-weight: 400;
  margin-top: 6px;
}
.btnImageLabelActive {
  font-weight: 400;
}
.btnImageIcon {
  font-size: 32px;
  height: 32px;
  margin: 0 auto;
  margin-bottom: 8px;
  width: 32px;
}
@media (max-width: 991px) {
  .modalActionButton {
    width: 100% !important;
  }
}
.shapesIcon {
  box-shadow: none;
  width: 34px;
}
.shapesIconWrap {
  background-color: transparent;
  border: none;
}
.shapesOption {
  height: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}
.shapesOptionActive {
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid black;
}
