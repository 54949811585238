.btn {
  margin-left: 12px;
}
@media screen and (max-width: 991px) {
  .btn {
    margin-bottom: 12px;
    margin-left: 0;
    width: 100%;
  }
}
.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
@media screen and (max-width: 991px) {
  .btnWrapper {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
}
.field {
  margin-bottom: 24px;
  max-width: 100%;
}
.group {
  display: flex;
  flex: 1;
}
.step {
  color: #767676;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 12px;
}
.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
}
.description {
  color: #505050;
  font-size: 16px;
  margin-bottom: 24px;
}
.required {
  color: #767676;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
.required .red {
  color: #b20033;
}
.spacer {
  width: 16px;
}
.tinyCardsWrapper {
  display: flex;
  margin-bottom: 40px;
}
.tinyCard {
  width: 56px;
  margin-right: 12px;
}
