@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.sc__mobile,
#public-wrapper .sc__mobile {
  background-color: #e5eef7;
  display: flex;
  height: 80vh;
  justify-content: center;
}
.sc__mobile__wrapper,
#public-wrapper .sc__mobile__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 45px 28px 56px 28px;
  text-align: center;
}
.sc__mobile__image,
#public-wrapper .sc__mobile__image {
  min-width: 137px;
}
.sc__mobile__title,
#public-wrapper .sc__mobile__title {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: bold;
  margin: 25px 0 0 0;
  max-width: 230px;
  padding: 0;
  text-transform: none;
}
.sc__mobile__text,
#public-wrapper .sc__mobile__text {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 200;
  margin: 8px 0 0 0;
  max-width: 304px;
  padding: 0;
}
.sc__mobile__button,
#public-wrapper .sc__mobile__button {
  color: #ffffff;
  margin-top: auto;
  width: 100%;
}
