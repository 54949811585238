.customizeCardButton {
  width: 280px;
}
.existingDraftsForCardSuiteModal {
  width: 552px;
  height: 479px;
}
@media (max-width: 767px) {
  .existingDraftsForCardSuiteModal {
    width: initial;
    height: initial;
  }
}
.mobileFullWidth {
  width: 280px;
}
@media screen and (max-width: 767px) {
  .mobileFullWidth {
    width: 100%;
  }
}
