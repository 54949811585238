.pdpModuleWrapper {
  border-top: 2px solid #d9d9d9;
  padding: 40px 0;
}
.pdpModuleHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.pdpModuleTitle {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
@media screen and (max-width: 767px) {
  .pdpModuleTitle {
    width: 50%;
  }
}
