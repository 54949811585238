.toggle {
  cursor: pointer;
}
.toggle img {
  display: block;
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
}
.toggle img.showOnHover {
  display: none !important;
}
.toggle:hover img.hideOnHover {
  display: none !important;
}
.toggle:hover img.showOnHover {
  display: block !important;
}
.animation {
  transform-origin: 50% 50%;
  animation-duration: 1s;
  animation-name: pulse;
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
