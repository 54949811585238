.add-guest-group-modal .tabs-pane > .error {
  margin-top: -24px;
  margin-bottom: 10px;
}
.add-guest-group-modal .upload__intro {
  font-size: 16px;
  color: #21201f;
  font-weight: 600;
  margin-bottom: 12px;
}
.add-guest-group-modal .upload__list {
  padding-left: 0;
  list-style-type: none;
}
.add-guest-group-modal .upload__list-item {
  color: #505050;
  line-height: 28px;
  margin-bottom: 4px;
  padding-left: 28px;
  font-size: 16px;
}
.add-guest-group-modal .upload__list .zolaicon-checkmark {
  color: white;
  background: #15845d;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  margin-left: -28px;
  margin-right: 8px;
  padding-left: 1px;
}
.add-guest-group-modal .upload__list .zolaicon-checkmark:before {
  vertical-align: top;
  padding-top: 3px;
}
.add-guest-group-modal .upload__example {
  margin-top: 20px;
  margin-bottom: 30px;
}
.add-guest-group-modal .upload__btn-container {
  text-align: center;
}
.add-guest-group-modal .upload__btn {
  width: 162px;
}
.add-guest-group-modal .upload__help {
  text-align: center;
}
@media (min-width: 767px) {
  .add-guest-group-modal .tabs-pane > .error {
    margin-top: -30px;
  }
  .upload__list-item {
    line-height: 16px;
    margin-bottom: 12px;
  }
}
