@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.request-missing-info-modal .modal-header {
  border-bottom: 1px solid #d9d9d9;
  margin: 0;
  padding: 30px 40px 20px 40px;
}
.request-missing-info-modal .modal-header h3 {
  font-size: 24px;
}
.request-missing-info-modal .modal-body {
  margin: 0 80px;
  padding: 0;
}
.request-missing-info-modal .modal-body .modal-body-section {
  border-bottom: 1px solid #d9d9d9;
  padding: 0 10px;
  padding-right: 0;
}
.request-missing-info-modal .modal-body .modal-body-section .modal-section-title {
  font-size: 18px;
}
.request-missing-info-modal .modal-body .modal-body-section .modal-section-description {
  font-size: 16px;
}
.request-missing-info-modal .modal-body .modal-body-section:last-of-type {
  border-bottom: none;
}
.request-missing-info-modal .modal-body .modal-body-section.by-link {
  padding-bottom: 20px;
}
.request-missing-info-modal .modal-body .modal-body-section.by-link .btn {
  width: 120px;
}
.request-missing-info-modal .modal-body .modal-body-section .guest-message {
  background-color: #f7f7f7;
  border: 1px solid #a8a8aa;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  padding-top: 10px;
}
.request-missing-info-modal .modal-body .modal-body-section .guest-message.grayed-out {
  opacity: 0.5;
}
.request-missing-info-modal .modal-body .modal-body-section .guest-message .form-group {
  margin-bottom: 0;
}
.request-missing-info-modal .modal-body .modal-body-section .guest-message .form-group .control-label {
  padding-top: 12px;
}
.request-missing-info-modal .modal-body .modal-body-section .guest-message .form-group .form-control-static {
  padding-bottom: 0;
}
.request-missing-info-modal .modal-body .modal-body-section .guest-message p {
  font-size: 14px;
}
.request-missing-info-modal .modal-body .form-group .form-control {
  display: inline-block;
}
.request-missing-info-modal .modal-body .missing-emails {
  margin-left: 10px;
}
.request-missing-info-modal .modal-body .missing-emails .add-guest-email .form-control {
  display: inline-block;
  margin-right: 10px;
  width: 300px;
}
.request-missing-info-modal .modal-body .missing-emails .add-guest-email .btn {
  width: 100px;
}
.request-missing-info-modal .modal-body .copy-link {
  margin-bottom: 5px;
}
.request-missing-info-modal .modal-body .copy-link .copy-link-input {
  color: #7f7f7f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}
.request-missing-info-modal .modal-body .copy-link .copy-link-actions {
  float: right;
  margin-left: 15px;
  margin-right: 10px;
  width: 250px;
}
.request-missing-info-modal .modal-body .copy-link-input {
  background-color: #f7f7f7;
  border: 1px solid #a8a8aa;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}
.request-missing-info-modal .modal-body .copy-link-label {
  font-size: 14px;
  margin-left: 10px;
}
.request-missing-info-modal .modal-body .copy-link-label a {
  margin-left: 5px;
}
.request-missing-info-modal .modal-footer {
  margin: 10px 10px 0 40px;
  padding-right: 0;
}
.request-missing-info-modal .modal-footer .form-group:last-of-type {
  margin-bottom: 0;
}
.request-missing-info__modal {
  padding: 24px 20px;
}
@media (min-width: 768px) {
  .request-missing-info__modal {
    padding: 24px 40px;
  }
}
@media (min-width: 992px) {
  .request-missing-info__modal {
    padding: 24px 60px;
  }
}
.request-missing-info__modal .-full-width {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
}
@media (min-width: 768px) {
  .request-missing-info__modal .-full-width {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 992px) {
  .request-missing-info__modal .-full-width {
    margin-left: -60px;
    margin-right: -60px;
    padding-left: 60px;
    padding-right: 60px;
  }
}
.request-missing-info__modal .-border-bottom {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 24px;
}
.request-missing-info__modal .modal__title,
.request-missing-info__modal .modal-section__title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}
.request-missing-info__modal .modal__body {
  margin-top: 24px;
}
@media (min-width: 992px) {
  .request-missing-info__modal .modal__body {
    margin-top: 30px;
  }
}
.request-missing-info__modal .modal-body__section:not(:first-child) {
  margin-top: 30px;
}
.request-missing-info__modal .modal-section__title {
  font-size: 18px;
}
.request-missing-info__modal .modal-section__description {
  color: inherit;
  font-size: 16px;
  margin: 0;
  margin-top: 15px;
}
.request-missing-info__modal .modal-section__description strong {
  font-weight: 400;
}
.request-missing-info__modal .missing-emails .text-danger {
  margin-top: 15px;
}
.request-missing-info__modal .copy-link,
.request-missing-info__modal .save-guest-email {
  display: flex;
  margin-top: 15px;
  max-width: 600px;
}
.request-missing-info__modal .save-guest-email {
  margin-top: 6px;
}
.request-missing-info__modal .guest-email-input {
  flex: 1;
  margin-right: 10px;
}
.request-missing-info__modal .copy-link {
  flex-direction: column;
}
@media (min-width: 768px) {
  .request-missing-info__modal .copy-link {
    flex-direction: row;
  }
}
.request-missing-info__modal .copy-link-input {
  background-color: #f7f7f7;
  border: 1px solid #a8a8aa;
  border-radius: 4px;
  flex: 1;
  order: 1;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .request-missing-info__modal .copy-link-input {
    margin-right: 10px;
  }
}
.request-missing-info__modal .copy-link-actions {
  margin-top: 15px;
  order: 2;
}
@media (min-width: 768px) {
  .request-missing-info__modal .copy-link-actions {
    margin-top: 0;
  }
}
.request-missing-info__modal .copy-link-label {
  color: inherit;
  font-size: 14px;
  margin-top: 15px;
}
.request-missing-info__modal .copy-link-label .text {
  display: block;
}
.request-missing-info__modal .copy-link-label .link {
  display: block;
  float: left;
}
@media (min-width: 768px) {
  .request-missing-info__modal .copy-link-label {
    margin-top: 6px;
  }
  .request-missing-info__modal .copy-link-label .text {
    display: inline;
  }
  .request-missing-info__modal .copy-link-label .link {
    display: inline;
    float: none;
  }
}
.request-missing-info__modal .guest-message {
  background-color: #f7f7f7;
  border: 1px solid #a8a8aa;
  margin-top: 30px;
  padding: 10px;
}
.request-missing-info__modal .guest-message .form-group {
  margin-bottom: 0;
}
.request-missing-info__modal .guest-message .form-group .control-label {
  padding-top: 12px;
}
.request-missing-info__modal .guest-message .form-group .form-control-static {
  padding-bottom: 0;
}
.request-missing-info__modal .guest-message p {
  font-size: 14px;
}
.request-missing-info__modal .guest-message.grayed-out {
  opacity: 0.5;
}
.request-missing-info__modal .modal__footer {
  text-align: right;
}
