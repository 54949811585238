.dropdown {
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  color: #505050;
  font-size: 16px;
  margin-top: -17px;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  z-index: 20;
}
.locationDropdown {
  margin-bottom: 10px;
  position: relative;
}
.locationDropdown :global .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  color: #505050;
  font-size: 16px;
  margin-top: -17px;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  z-index: 20;
  max-height: 280px;
  overflow-y: scroll;
}
.locationDropdown :global .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open ul {
  list-style-type: none;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-block-end: 0;
}
.locationDropdown :global .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open .react-autosuggest__suggestion {
  width: 100%;
  cursor: pointer;
  font-weight: 400;
}
.locationDropdown :global .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open .react-autosuggest__suggestion:hover,
.locationDropdown :global .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open .react-autosuggest__suggestion.react-autosuggest__suggestion--highlighted {
  background: #f7f7f7;
  color: #21201f;
}
.locationOption {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  line-height: 24px;
}
.geoIcon {
  height: 25px;
  width: 25px;
  margin-right: 8px;
}
.locationOptionNotListed {
  border-top: 1px solid #d9d9d9;
}
