@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.related-cards-carousel {
  margin: 0 -20px;
}
.related-cards-carousel__section {
  background: #fff;
  border-top: 1px solid #d9d9d9;
  margin: 0 -10px;
}
.related-cards-carousel__section--noBorder {
  border-top: none;
}
.related-cards-carousel__section .related-cards-carousel__title {
  font-family: 'circular', helvetica, sans-serif;
  letter-spacing: 0.2px;
  margin: 0 0 40px;
  text-align: center;
}
@media (max-width: 768px) {
  .related-cards-carousel__section .related-cards-carousel__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}
.related-cards-carousel__section .related-cards-carousel__title--ligther {
  color: #767676;
}
.related-cards-carousel__section .related-cards-carousel__subtitle {
  display: block;
  margin-bottom: 34px;
}
.related-cards-carousel__section .related-cards-carousel__title--emphasis {
  color: #21201f;
}
.related-cards-carousel__section .related-cards-carousel__content {
  margin-bottom: 40px;
  text-align: center;
}
.related-cards-carousel__section .related-cards-carousel__content .image img,
.related-cards-carousel__section .related-cards-carousel__content .image--is-bigger img {
  width: 60%;
}
.related-cards-carousel__section .related-cards-carousel__content div:not([class*='toggle']) > img {
  display: inline-block;
}
.related-cards-carousel__section .related-cards-carousel__content .slick-list {
  min-height: 354px;
}
.related-cards-carousel__section .related-cards-carousel__content .slick-slide {
  visibility: visible !important;
}
.related-cards-carousel__section .related-cards-carousel__content .btn {
  padding: 0;
}
.related-cards-carousel__section .related-cards-carousel__content .btn.slick-prev,
.related-cards-carousel__section .related-cards-carousel__content .btn.slick-next {
  top: 31%;
}
@media (max-width: 1200px) {
  .related-cards-carousel__section .related-cards-carousel__content .btn.slick-prev,
  .related-cards-carousel__section .related-cards-carousel__content .btn.slick-next {
    top: 25%;
  }
}
@media (max-width: 992px) {
  .related-cards-carousel__section .related-cards-carousel__content .btn.slick-prev,
  .related-cards-carousel__section .related-cards-carousel__content .btn.slick-next {
    top: 30%;
  }
}
@media (max-width: 768px) {
  .related-cards-carousel__section .related-cards-carousel__content .btn.slick-prev,
  .related-cards-carousel__section .related-cards-carousel__content .btn.slick-next {
    top: 22%;
  }
}
.related-cards-carousel__section .related-cards-carousel__wrapper {
  outline: none;
}
.related-cards-carousel__section .related-cards-carousel__wrapper .listing-detail {
  margin-bottom: 10px;
  text-align: left;
}
.related-cards-carousel__section .related-cards-carousel__wrapper .listing-detail .link:hover {
  text-decoration: none;
}
.related-cards-carousel__section .related-cards-carousel__more-link {
  color: #505050;
  font-size: 12px;
}
