@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.generic-accordion .accordion-section {
  position: relative;
}
.generic-accordion .accordion-section h4 {
  margin: 0;
  padding: 15px;
  border-bottom: solid 1px #d9d9d9;
}
.generic-accordion .accordion-section h4:hover {
  background-color: #f3f3f3;
}
.generic-accordion .accordion-section h4 i {
  color: #7f7f7f;
  font-size: 14px;
}
.generic-accordion .accordion-section > .accordion-body {
  height: 0;
  overflow-y: hidden;
}
.generic-accordion .accordion-section.selected > .accordion-body {
  height: auto;
  padding: 15px 25px;
}
.generic-accordion .accordion-section:last-child {
  margin-bottom: 15px;
}
