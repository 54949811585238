.other-registries__edit-modal {
  padding: 20px 40px 35px 30px;
}
.other-registries__edit-modal p {
  margin-bottom: 40px;
}
.other-registries__edit-modal .other-registries__edit-modal__header {
  color: #21201f;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}
.other-registries__edit-modal .other-registries__edit-modal__error-block {
  background-color: #c7133e;
  color: #fff;
  font-size: 12px;
  font-weight: 100;
  padding: 2px 16px;
}
.other-registries__edit-modal #other-registries__edit-modal__input {
  color: #21201f;
  font-size: 14px;
  overflow: hidden;
  padding-left: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.other-registries__edit-modal .other-registries__edit-modal__input__icon {
  height: auto;
  margin-left: 10px;
  margin-top: 8px;
  position: absolute;
  width: 18px;
  z-index: 1;
}
.other-registries__edit-modal button {
  margin-bottom: 12px;
  width: 285px;
}
@media (min-width: 767px) {
  .other-registries__edit-modal button {
    margin-bottom: 0;
    margin-left: 6px;
    margin-right: 6px;
    width: 210px;
  }
}
.other-registries__edit-modal .other-registries__edit-modal_ctas {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
@media (min-width: 767px) {
  .other-registries__edit-modal .other-registries__edit-modal_ctas {
    flex-direction: row;
    justify-content: flex-end;
  }
}
