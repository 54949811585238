@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.accordionsWrapper {
  margin-top: 32px;
}
.accordionsWrapper h2,
.accordionsWrapper h3 {
  margin-top: 0;
}
@media (max-width: 767px) {
  .accordionsWrapper.pdpOptimize {
    margin-bottom: 24px;
  }
}
