:global .paginated-cards__container .col-sm-3,
:global .paginated-cards__container .col-md-3 {
  padding-left: 10px;
  padding-right: 10px;
}
:global .card-listing .col-sm-12,
:global .card-listing .col-md-12 {
  padding-left: 10px;
  padding-right: 10px;
}
:global .card-tile-container {
  margin-bottom: 60px;
  min-height: 300px;
}
:global .listing-title {
  color: #21201f;
}
:global .modal-open {
  overflow: hidden;
}
:global .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
:global .modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
:global .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
:global .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
:global .modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
:global .modal-content {
  position: relative;
  background-color: #fcfcfc;
  background-clip: padding-box;
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  outline: 0;
}
:global .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
:global .modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}
:global .modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
:global .modal-header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
:global .modal-header .close {
  margin-top: -2px;
}
:global .modal-title {
  margin: 0;
  line-height: 1.42857143;
}
:global .modal-body {
  position: relative;
  padding: 20px;
}
:global .modal-footer {
  padding: 20px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
:global .modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}
:global .modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
:global .modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
:global .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  :global .modal-dialog {
    width: 748px;
    margin: 30px auto;
  }
  :global .modal-sm {
    width: 500px;
  }
  :global .modal-xs {
    width: 360px;
  }
}
@media (min-width: 992px) {
  :global .modal-lg {
    width: 900px;
  }
}
:global .modal-close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  font-size: 26px;
  line-height: 1;
  width: 26px;
  height: 26px;
  font-weight: 100;
  color: #0075ae;
}
:global .modal-close:hover {
  color: #179caa;
}
