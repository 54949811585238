.review-envelopes-modal {
  padding: 40px;
}
@media (max-width: 767px) {
  .review-envelopes-modal {
    padding: 40px 20px 20px;
  }
}
.review-envelopes-modal .addressing-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5eef7;
  color: #336081;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  gap: 8px;
  margin-bottom: 20px;
}
.review-envelopes-modal .addressing-tiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
