.leavePromptModalContainer {
  padding: 40px;
}
.leavePromptModalContainer .header {
  font-size: 20px;
  font-weight: 600;
}
.leavePromptModalContainer .buttonContainer {
  padding-top: 32px;
  display: flex;
  flex-direction: row-reverse;
}
.leavePromptModalContainer .buttonPadding {
  margin-right: 12px;
}
