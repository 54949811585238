.processingWrapper {
  color: #767676;
  margin-bottom: 40px;
  text-align: center;
}
.processingIcon {
  margin: 0 auto 20px;
  width: 54px;
}
.interstitialWrapper {
  display: flex;
  justify-content: space-between;
}
.interstitial {
  color: #21201f;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-align: center;
  width: 127px;
}
.hidden {
  visibility: hidden;
}
.interstitialIcon {
  margin: 0 auto 20px;
  width: 72px;
}
