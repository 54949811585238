.add-guest-group-modal > .no-nav {
  margin-top: 36px;
}
.add-guest-group-modal > .no-nav .horizontal-nav .row {
  display: none;
}
.customization-container--no-guests.centered {
  justify-content: center;
}
.customization-container--no-guests > .upload-csv__loading-emojis {
  margin-top: 15%;
  width: 100%;
}
.customization-container--no-guests > .add-guest-group-modal.upload__success {
  width: 110%;
  margin: 0 -30px;
  min-height: 600px;
}
.no-guests__panel > .no-guests__title {
  margin-top: 50px;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  font-size: 20px;
}
.no-guests__upload > .upload__intro {
  margin-top: 32px;
  font-weight: 600;
  color: #21201f;
  font-size: 16px;
}
.no-guests__upload > .upload__list {
  margin-top: 12px;
  padding-left: 0;
  list-style-type: none;
}
.no-guests__upload > .upload__list > .upload__list-item {
  color: #505050;
  line-height: 28px;
  margin-bottom: 4px;
  padding-left: 28px;
  font-size: 16px;
  font-weight: normal;
}
.no-guests__upload > .upload__list .zolaicon-checkmark {
  color: white;
  background: #15845d;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  font-size: 9px;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  margin-left: -28px;
  margin-right: 8px;
  padding-left: 1px;
}
.no-guests__upload > .upload__list .zolaicon-checkmark:before {
  vertical-align: top;
  padding-top: 3px;
}
.no-guests__upload > .upload__example {
  margin-top: 16px;
  margin-bottom: 32px;
}
.no-guests__panel > .upload__help {
  margin-top: 6px;
  color: #505050;
  font-weight: normal;
}
.no-guests__panel > .no-guests__add {
  margin-top: 40px;
  margin-bottom: 60px;
}
.no-guests__upload > .file-input-container {
  margin-top: 32px;
}
.no-guests__add > .add__text {
  color: #505050;
  font-weight: 600;
}
.center-btn {
  margin-left: 50% !important;
  transform: translateX(-50%);
  width: 162px;
}
