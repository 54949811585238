/* stylelint-disable declaration-no-important */
.tooltipWrapper {
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.tooltipPortalRoot {
  cursor: pointer;
  position: absolute;
}
.tooltipPortalRoot:empty {
  display: none;
}
.tooltip {
  cursor: initial;
  position: absolute;
  visibility: hidden;
  z-index: 1000;
}
.tooltip::before {
  background: transparent;
  border: 6px solid white;
  content: "";
  position: absolute;
}
.visible {
  visibility: visible;
}
.tooltipContent {
  background: white;
  border-radius: 4px;
  box-shadow: 6px 2px 12px rgba(0, 59, 88, 0.16);
  padding: 20px;
  width: 300px;
}
.tooltipTop {
  bottom: 100%;
  padding-bottom: 6px;
}
.tooltipTop::before {
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  bottom: 0;
  margin-bottom: -6px;
}
.tooltipRight {
  left: 100%;
  padding-left: 6px;
}
.tooltipRight::before {
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  margin-left: -6px;
  left: 0;
}
.tooltipBottom {
  top: 100%;
  padding-top: 6px;
}
.tooltipBottom::before {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: 0;
  margin-top: -6px;
}
.tooltipLeft {
  padding-right: 6px;
  right: 100%;
}
.tooltipLeft::before {
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  margin-right: -6px;
  right: 0;
}
.alignHorizontalCenter {
  left: 50%;
  transform: translateX(-50%);
}
.alignHorizontalCenter::before {
  left: 50%;
  transform: translateX(-50%);
}
.alignVerticalCenter {
  top: 50%;
  transform: translateY(-50%);
}
.alignVerticalCenter::before {
  top: 50%;
  transform: translateY(-50%);
}
.alignTop {
  top: -10px;
}
.alignTop::before {
  top: 12px;
}
.alignRight {
  right: -10px;
  transform: translateX(10px);
}
.alignRight::before {
  right: 12px;
  transform: translateX(-12px);
}
.alignBottom {
  bottom: -10px;
}
.alignBottom::before {
  bottom: 12px;
}
.alignLeft {
  left: -10px;
  transform: translateX(10px);
}
.alignLeft::before {
  left: 12px;
  transform: translateX(-12px);
}
