@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.listing-detail {
  margin-bottom: 60px;
  min-height: 300px;
  text-align: center;
}
.listing-detail .banner {
  border-radius: 0;
  width: auto;
  position: absolute;
  left: 0;
}
.listing-detail .banner.new {
  top: 0;
  background-color: #45607f;
}
.listing-detail .banner.match {
  background-color: #c5e4ea;
  color: #003c57;
  bottom: 0;
}
.listing-detail .collaborator-link {
  font-size: 16px;
}
.listing-detail .collaborator-link:hover {
  text-decoration: underline;
}
.listing-detail .link:hover {
  cursor: pointer;
  text-decoration: none;
}
.listing-detail .link:hover .details .product-name.gray {
  color: #4a4a4a;
}
.listing-detail .image {
  position: relative;
  overflow: visible;
  text-align: center;
}
.listing-detail .image img {
  width: 60%;
  height: 100%;
}
.listing-detail .image .link {
  position: absolute;
  bottom: -80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 25px;
  transition: bottom 0.5s;
}
.listing-detail .image .link span {
  border-radius: 0;
  width: 100%;
}
.listing-detail .image .link:hover {
  cursor: pointer;
}
.listing-detail .image:hover .link {
  bottom: 0;
}
.listing-detail .image.image--is-bigger img {
  width: 100%;
}
.listing-detail .image.sample-pack-img img {
  width: 65%;
}
.listing-detail .image.size-to-redesign img {
  padding: 12px;
  width: 65%;
}
.listing-detail .details.details--less-margin {
  margin-top: 8px;
}
.listing-detail .details .product-name {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
.listing-detail .details .product-name.gray {
  color: #414142;
}
.listing-detail .details .product-pricing {
  color: #c7133e;
  margin-bottom: 12px;
}
.listing-detail .details .product-pricing.red {
  color: #c7133e;
}
.listing-redesign-override {
  padding-left: 12px;
  padding-right: 12px;
}
.listing-redesign-override .listing-detail {
  margin-bottom: 44px;
}
.listing-redesign-override .listing-detail .image.image--is-bigger {
  overflow: visible;
}
.listing-redesign-override .listing-detail .image.image--is-bigger img {
  width: 100%;
}
