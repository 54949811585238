/**
 * @prettier
 */
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.tileHeader {
  color: #21201f;
  font-size: 24px;
  font-weight: 500;
}
.tilePrice {
  color: #c7133e;
  font-size: 14px;
  font-weight: 500;
}
.tileHeaderBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 12px 0 12px;
}
.tileImage {
  width: 100%;
}
.tileDescription {
  padding: 12px;
  font-size: 14px;
}
.tileQualityItemHeader {
  color: #767676;
  font-size: 12px;
  font-weight: 500;
}
.tileQualityItemBody {
  font-size: 14px;
}
.tileQualityItem {
  padding: 0 12px 12px 0;
}
.tileQualityBox {
  display: flex;
  padding: 12px;
  flex-direction: row;
}
@media (max-width: 480px) {
  .tileQualityBox {
    flex-direction: column;
  }
}
.tile {
  display: flex;
  flex-direction: column;
  margin: 20px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  flex: 1 1 40%;
}
@media (max-width: 767px) {
  .tile {
    flex: 1 1 100%;
  }
}
.tilesContainer {
  display: flex;
  flex-flow: row wrap;
  margin: 0px 0px;
}
.title {
  font-size: 32px;
  font-weight: 500;
  margin: 12px 20px;
  line-height: 1;
}
.proofBanner {
  background-color: #fdf0f1;
  background-image: url('../../../../assets/images/invitations/papertypes/freesample-banner.svg');
  background-size: cover;
  border-radius: 4px;
  padding: 12px;
  margin: 12px 20px;
}
.box {
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  padding: 20px 0;
}
@media (max-width: 767px) {
  .box {
    max-width: 640px;
    align-self: center;
    margin: auto;
  }
}
