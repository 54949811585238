@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
#edit-guest-group-modal .modal-header h3 {
  padding-left: 20px;
}
#edit-guest-group-modal .modal-header .modal-button-right {
  text-align: right;
}
#edit-guest-group-modal .modal-header .modal-button-right > .btn {
  margin-right: 15px;
}
#edit-guest-group-modal .modal-body {
  padding: 0;
}
#edit-guest-group-modal .modal-body .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
#edit-guest-group-modal .modal-body form {
  padding-bottom: 30px;
}
#edit-guest-group-modal .modal-body form .tabs-labels {
  padding-left: 80px;
}
@media (max-width: 767px) {
  #edit-guest-group-modal .modal-body form .tabs-labels {
    padding-left: 40px;
  }
}
#edit-guest-group-modal .modal-body form .tabs-content {
  padding-left: 80px;
  padding-right: 80px;
}
@media (max-width: 767px) {
  #edit-guest-group-modal .modal-body form .tabs-content {
    padding-left: 40px;
    padding-right: 40px;
  }
}
#edit-guest-group-modal .modal-body form .edit-guest-submit {
  margin-left: 100px;
}
#edit-guest-group-modal .modal-body form .row.form-group {
  margin-bottom: 15px;
}
#edit-guest-group-modal .modal-body form .row.form-group .guest-unknown-zolaicon {
  position: relative;
  right: 29px;
  top: 9px;
}
@media (max-width: 991px) {
  #edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 {
    margin-bottom: 10px;
  }
}
#edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 .title-container .with-tooltip select {
  width: 105px;
}
#edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 .form-label .with-tooltip .title {
  font-size: 12px;
  text-transform: uppercase;
}
#edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 .form-label .with-tooltip .checkbox input .input-override .zolaicon.zolaicon-checkmark {
  font-size: 12px;
}
#edit-guest-group-modal .modal-body form .form-group .guest-delete {
  bottom: 0;
  color: #0e0e0e;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.2;
  position: absolute;
  right: 10px;
}
@media (min-width: 992px) {
  #edit-guest-group-modal .modal-body form .form-group .guest-delete {
    bottom: 12px;
    color: #21201f;
    right: -8px;
  }
}
@media (min-width: 992px) {
  #edit-guest-group-modal .modal-body form .form-group .with-tooltip .wedding-label {
    position: relative;
    top: 3px;
  }
}
#edit-guest-group-modal .envelope-recipient-container .envelope {
  display: flex;
}
#edit-guest-group-modal .envelope-recipient-container .envelope-recipient {
  display: flex;
  flex-direction: column;
  font-weight: 700;
}
#edit-guest-group-modal .envelope-recipient-container i.glyphicon-pencil {
  color: #7f7f7f;
  font-size: 16px;
  margin-left: 20px;
}
#edit-guest-group-modal .envelope-recipient-container i.glyphicon-pencil:hover {
  color: #0e0e0e;
  cursor: pointer;
}
#edit-guest-group-modal #edit-guest-group-envelope_recipient_override {
  width: 70%;
}
#edit-guest-group-modal .edit-guest-group-form .form-group {
  margin-bottom: 15px;
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:before,
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  display: table;
  content: " ";
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  clear: both;
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:before,
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  display: table;
  content: " ";
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  clear: both;
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container .edit-guest-group-submit {
  padding-right: 30px;
}
#edit-guest-group-modal .edit-guest-group-form .rsvp-status.no-invites {
  margin: 0 auto;
  padding-bottom: 80px;
  padding-top: 80px;
  text-align: center;
}
#edit-guest-group-modal .edit-guest-group-form .rsvp-status.no-invites .no-invites-copy {
  font-size: 16px;
  margin: 0 auto;
  max-width: 500px;
}
#edit-guest-group-modal .title-container .with-tooltip select.form-control {
  position: relative;
  top: 0;
}
#edit-guest-group-modal .generic-accordion .accordion-section:first-child {
  border-top: solid 1px #e6e6e6;
}
#edit-guest-group-modal .generic-accordion .accordion-section.selected {
  border-bottom: solid 1px #d9d9d9;
}
#edit-guest-group-modal .generic-accordion .accordion-section.selected h4 {
  border-bottom: none;
}
#edit-guest-group-modal .list-inline.add-guest-group-buttons > li {
  margin: 5px auto;
}
#edit-guest-group-modal .event-invitations .event-invitations-disabled {
  filter: opacity(40%);
}
#edit-guest-group-modal .event-invitations .checkbox-inline {
  font-weight: 400;
}
#edit-guest-group-modal .event-invitations .checkbox-inline.checkbox-disabled .input-override {
  background-color: white;
  border-color: #d9d9d9;
}
#edit-guest-group-modal .event-invitations .checkbox-inline.checkbox-disabled .input-override:hover {
  border-color: #d9d9d9;
}
.tabs ul.tabs-labels {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.tabs ul.tabs-labels > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
.tabs .tabs-content .tabs-pane .with-tooltip.edit-guest-name {
  display: block;
}
.tabs .tabs-content .tabs-pane .with-tooltip.edit-guest-name:before,
.tabs .tabs-content .tabs-pane .with-tooltip.edit-guest-name:after {
  bottom: 100%;
  left: 32%;
}
