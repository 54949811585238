.container {
  background: #fdf0f1 url('~/assets/images/invitations/customNoteBackground.svg') no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 0;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 25px;
  position: relative;
}
.content-wrapper {
  max-width: 311px;
}
.title {
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #21201f;
  margin: 0 0 12px;
}
.content {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.6;
  margin: 0;
  max-width: 290px;
  position: relative;
  z-index: 1;
}
.price {
  color: #c7133e;
  font-weight: 600;
}
.illustration {
  bottom: -4px;
  position: absolute;
  right: 25px;
  width: 36%;
  z-index: 0;
}
@media screen and (max-width: 1199px) {
  .content {
    max-width: 240px;
  }
  .illustration {
    bottom: 4px;
    width: 29%;
  }
}
@media screen and (max-width: 991px) {
  .content-wrapper {
    max-width: none;
  }
  .content {
    max-width: 450px;
  }
  .illustration {
    bottom: -3px;
    right: 35px;
    width: 25%;
  }
}
@media screen and (max-width: 767px) {
  .container {
    flex-direction: column;
    justify-content: center;
    padding-top: 150px;
    text-align: center;
  }
  .content {
    margin: 0 auto;
  }
  .illustration {
    bottom: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: -34px;
    width: auto;
  }
  .img {
    max-width: 25%;
  }
}
@media screen and (max-width: 415px) {
  .img {
    max-width: 48%;
  }
}
