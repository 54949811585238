.contentInnerWrapper {
  display: flex;
  flex-direction: row;
}
.column {
  flex: 0 0 30px;
}
.column + .column {
  flex: 1 1 auto;
}
.title {
  color: #21201f;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 4px 0;
}
.text {
  color: #505050;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}
.text + .text {
  margin-top: 10px;
}
