.horizontal-alignment {
  left: 50%;
  transform: translateX(-50%);
}
.content-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
}
.column {
  width: 50%;
  padding: 0 28px 0 0;
}
.column + .column {
  padding: 0;
}
.title {
  color: #000000;
  font-size: 24px;
  line-height: 36px;
  margin: 0 0 10px 0;
}
.sub-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 4px 0;
}
.text {
  color: #505050;
  line-height: 20px;
  margin: 0 0 20px 0;
}
.img-wrapper {
  padding: 0 0 50px 0;
  position: relative;
}
.img__legend {
  bottom: 0;
  font-size: 16px;
  font-weight: 600;
  left: 50px;
  line-height: 20px;
  position: absolute;
}
.img__legend + .img__legend {
  left: auto;
  right: 50px;
}
.img__legend + .img__legend::before {
  height: 29px;
  top: -40px;
}
.img__legend + .img__legend::after {
  top: -43px;
}
.img__legend::before {
  left: 50%;
  transform: translateX(-50%);
  background: #21201F;
  top: -51px;
  content: " ";
  height: 40px;
  position: absolute;
  width: 1px;
}
.img__legend::after {
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border: 1px solid #21201F;
  border-radius: 100%;
  top: -54px;
  content: " ";
  position: absolute;
  height: 6px;
  width: 6px;
}
@media screen and (max-width: 991px) {
  .content-wrapper {
    flex-direction: column;
    padding: 40px 20px 20px;
  }
  .column {
    padding: 0 0 30px;
    text-align: center;
    width: 100%;
  }
  .column + .column {
    text-align: left;
  }
  .img {
    display: block;
    margin: 0 auto;
    max-width: 240px;
  }
  .img-wrapper {
    display: inline-block;
  }
  .img__legend {
    left: 25px;
  }
  .img__legend::after {
    top: -51px;
  }
  .img__legend + .img__legend {
    right: 25px;
  }
}
