.container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 20px;
  max-height: 448px;
}
@media (max-width: 767px) {
  .container {
    max-height: 50vh;
  }
}
