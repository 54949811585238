.upload-csv__loading-emojis {
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 60px;
  margin-top: 80px;
}
.upload-csv__loading-txt {
  text-align: center;
  margin-bottom: 125px;
  font-size: 20px;
  font-weight: 600;
  color: #505050;
}
