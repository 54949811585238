.optionSectionTitleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.optionSectionTitle {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: #0e0e0e;
  margin-right: 16px;
}
.optionSectionSubtitle {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #757575;
  margin-right: 8px;
}
.optionSectionDimensions {
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #757575;
  margin-right: 8px;
}
.optionSectionPrice {
  font-family: 'circular', serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #c7133e;
}
.optionSectionToggleContainer {
  margin-bottom: 40px;
}
.optionSectionLink {
  font-weight: 600;
}
.mobileLink {
  margin-top: 32px;
  text-align: right;
}
.condensed .optionSectionToggleContainer {
  margin-bottom: 30px;
}
.condensed .optionSectionTitleArea {
  margin-bottom: 10px;
}
.condensed .optionSectionTitle {
  font-weight: 600;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .maxWidth {
    max-width: 545px;
  }
}
.extraCustomizationDescription {
  background-color: #f5f9fc;
  border-radius: 40px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  padding: 8px 10px;
  text-align: center;
}
@media (max-width: 480px) {
  .extraCustomizationDescription {
    font-size: 14px;
  }
}
.extraCustomizationDescription .learnMore {
  margin-left: 8px;
}
.informationTooltipFoilCoilors {
  margin-right: 7px;
  vertical-align: middle;
}
