/**
 * Tooltip Styles
 */
/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  cursor: pointer;
  position: relative;
}
/* Base styles for the entire tooltip */
[data-tooltip]::before,
[data-tooltip]::after,
.tooltip::before,
.tooltip::after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:dximagetransform.microsoft.alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 50ms ease-out, visibility 500ms ease-out, -webkit-transform 500ms cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 50ms ease-out, visibility 500ms ease-out, -moz-transform 500ms cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 50ms ease-out, visibility 500ms ease-out, transform 500ms cubic-bezier(0.71, 1.7, 0.77, 1.24);
  visibility: hidden;
}
/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover::before,
[data-tooltip]:hover::after,
[data-tooltip]:focus::before,
[data-tooltip]:focus::after,
.tooltip:hover::before,
.tooltip:hover::after,
.tooltip:focus::before,
.tooltip:focus::after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:dximagetransform.microsoft.alpha(Opacity=100);
  opacity: 1;
  visibility: visible;
}
/* Base styles for the tooltip's directional arrow */
.tooltip::before,
[data-tooltip]::before {
  background: transparent;
  border: 6px solid transparent;
  content: "";
  z-index: 1001;
}
/* Base styles for the tooltip's content area */
.tooltip::after,
[data-tooltip]::after {
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.2;
  padding: 8px;
  text-align: center;
  text-transform: none;
  width: 160px;
  z-index: 1000;
}
@media (max-width: 991px) {
  .no-tooltip-on-mobile::before,
  .no-tooltip-on-mobile::after {
    content: none;
  }
}
.hide-tooltip::before,
.hide-tooltip::after {
  content: none;
}
/* Directions */
/* Top (default) */
[data-tooltip]::before,
[data-tooltip]::after,
.tooltip::before,
.tooltip::after,
.tooltip-top::before,
.tooltip-top::after {
  bottom: 100%;
  font-family: 'circular', helvetica, sans-serif;
  left: 50%;
}
[data-tooltip]::before,
.tooltip::before,
.tooltip-top::before {
  border-top-color: #000;
  border-top-color: rgba(51, 51, 51, 0.9);
  margin-bottom: -12px;
  margin-left: -6px;
}
/* Horizontally align top/bottom tooltips */
[data-tooltip]::after,
.tooltip::after,
.tooltip-top::after {
  margin-left: -80px;
}
[data-tooltip]:hover::before,
[data-tooltip]:hover::after,
[data-tooltip]:focus::before,
[data-tooltip]:focus::after,
.tooltip:hover::before,
.tooltip:hover::after,
.tooltip:focus::before,
.tooltip:focus::after,
.tooltip-top:hover::before,
.tooltip-top:hover::after,
.tooltip-top:focus::before,
.tooltip-top:focus::after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
  transition: all 500ms ease-out;
  transition-delay: 1s;
}
/* Left */
.tooltip-left::before,
.tooltip-left::after {
  bottom: 50%;
  left: auto;
  right: 100%;
}
.tooltip-left::before {
  border-left-color: #000;
  border-left-color: rgba(51, 51, 51, 0.9);
  border-top-color: transparent;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: -12px;
}
.tooltip-left:hover::before,
.tooltip-left:hover::after,
.tooltip-left:focus::before,
.tooltip-left:focus::after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}
/* Bottom */
.tooltip-bottom::before,
.tooltip-bottom::after {
  bottom: auto;
  left: 50%;
  top: 100%;
}
.tooltip-bottom::before {
  border-bottom-color: #000;
  border-bottom-color: rgba(51, 51, 51, 0.9);
  border-top-color: transparent;
  margin-bottom: 0;
  margin-top: -12px;
}
.tooltip-bottom:hover::before,
.tooltip-bottom:hover::after,
.tooltip-bottom:focus::before,
.tooltip-bottom:focus::after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}
/* Right */
.tooltip-right::after {
  bottom: auto;
  left: 100%;
  margin-left: -12px;
  top: 50%;
  -webkit-transform: translate(12px, -50%);
  -moz-transform: translate(12px, -50%);
  transform: translate(12px, -50%);
}
.tooltip-right::before {
  border-right-color: #000;
  border-right-color: rgba(51, 51, 51, 0.9);
  border-top-color: transparent;
  left: 100%;
  margin-bottom: 0;
  margin-left: -12px;
}
.tooltip-right:hover::before,
.tooltip-right:hover::after,
.tooltip-right:focus::before,
.tooltip-right:focus::after {
  bottom: auto;
  top: 50%;
  -webkit-transform: translate(12px, -50%);
  -moz-transform: translate(12px, -50% 0);
  transform: translate(12px, -50%);
}
.tooltip-right:hover::after,
.tooltip-right:focus::after {
  margin-left: 0;
}
/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left::before,
.tooltip-right::before {
  top: calc(47%);
}
/* Vertically center tooltip content for left/right tooltips */
.tooltip-left::after {
  margin-bottom: -16px;
  margin-left: 0;
}
