.checklist__confirmRemoval--container {
  padding: 15px 15px 25px;
  text-align: center;
  max-width: 465px;
  margin: auto;
}
@media (min-width: 992px) {
  .checklist__confirmRemoval--container {
    padding: 30px 0;
  }
}
.checklist__confirmRemoval--warningCopy {
  font-size: 14px;
}
.checklist__confirmRemoval--footer {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .checklist__confirmRemoval--footer {
    max-width: 240px;
    margin: 25px auto 0;
  }
}
.checklist__confirmRemoval--cancel {
  margin-top: 25px;
}
