@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.option-list-wrapper {
  position: relative;
}
@media (max-width: 991px) {
  .option-list-wrapper {
    display: none;
  }
}
.option-list {
  display: block;
  position: absolute;
  z-index: 101;
  left: -234px;
  transform: translateY(-50%);
  -moz-transform: translateY(-96px);
  top: 46px;
}
.option-list-recommended {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  line-height: 16px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 600;
}
.option-list-recommended-emoji {
  font-size: 12px;
  padding: 1px 0;
}
.product-color-picker {
  position: relative;
}
.product-color-picker__wrapper {
  border: 2px solid transparent;
  border-radius: 100%;
  box-sizing: content-box;
  display: inline-block;
  height: 44px;
  margin: 0 2px;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 44px;
}
@media (max-width: 767px) {
  .product-color-picker__wrapper {
    height: 44px;
    width: 44px;
  }
  .product-color-picker__wrapper.circle-wrapper--extra-spacing {
    margin: 0 10px 8px 2px;
  }
}
.product-color-picker__wrapper--active {
  border: 2px solid #505050;
}
.product-color-picker__circle-front {
  border: 1px solid transparent;
  border-radius: 100%;
  cursor: pointer;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 40px;
  -webkit-transform: translate(-20px, -20px);
  -ms-transform: translate(-20px, -20px);
  -o-transform: translate(-20px, -20px);
  transform: translate(-20px, -20px);
}
@media (max-width: 767px) {
  .product-color-picker__circle-front {
    height: 38px;
    width: 38px;
    -webkit-transform: translate(-19px, -19px);
    -ms-transform: translate(-19px, -19px);
    -o-transform: translate(-19px, -19px);
    transform: translate(-19px, -19px);
  }
}
.product-color-picker__circle-back {
  background: #ffffff;
  border: 1px solid #D9D9D9;
  border-radius: 100%;
  cursor: pointer;
  height: 42px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 42px;
  -webkit-transform: translate(-21px, -21px);
  -ms-transform: translate(-21px, -21px);
  -o-transform: translate(-21px, -21px);
  transform: translate(-21px, -21px);
}
@media (max-width: 767px) {
  .product-color-picker__circle-back {
    height: 40px;
    width: 40px;
    -webkit-transform: translate(-20px, -20px);
    -ms-transform: translate(-20px, -20px);
    -o-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}
