.account {
  display: flex;
  margin-top: 20px;
}
.createAccount {
  padding-right: 35px;
}
@media screen and (max-width: 991px) {
  .createAccount {
    padding-right: 0;
    width: 100%;
  }
}
.login {
  border-left: 2px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 35px;
}
.grecaptchaBadge {
  padding-top: 30px;
}
