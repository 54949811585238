.mapping-intro__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mapping-intro__content .intro__title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}
.mapping-intro__content .intro__text {
  font-size: 16px;
  color: #505050;
  margin: 0 100px 20px;
  text-align: center;
  width: 550px;
}
.mapping-intro__content .intro__btn.zola-ui.button {
  margin: 30px auto 0;
  display: block;
}
.mapping-intro__content .intro__video {
  width: 660px;
  display: block;
  margin-bottom: 32px;
}
