.container {
  background-color: #ffffff;
  overflow-x: hidden;
  transition: background-color 500ms ease-in;
}
.container :global(.card-listing-wrapper-preauth) {
  margin: 0 auto;
  max-width: 1920px;
}
@media (min-width: 1920px) {
  .container :global(.card-listing-wrapper-preauth) {
    border-left: 1px solid #b7b7b7;
    border-right: 1px solid #b7b7b7;
    overflow-x: hidden;
  }
}
@media (min-width: 992px) {
  .postAuthNav3Shown {
    padding-left: 74px;
  }
}
