@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.tableModal {
  margin: 0 20px;
  padding: 15px;
}
.tableModal .tableModal__header {
  font-size: 28px;
}
.tableModal .tableModal__text {
  font-size: 16px;
}
.tableModal .tableModal__item {
  font-size: 16px;
  padding: 5px 0;
  max-height: 32px;
  width: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tableModal .tableModal__itemContainer {
  display: flex;
  flex-wrap: wrap;
}
.form-group .add-table {
  padding: 10px;
}
.form-group .add-table-disabled {
  padding: 10px;
  color: #a8a8aa;
  cursor: default;
}
.form-group .add-table-warning {
  padding-left: 10px;
  color: #a8a8aa;
  font-size: 14px;
}
.form-group .table-warning {
  padding-left: 10px;
  color: #c7133e;
  font-size: 14px;
}
.tableModal__buttonGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 15px;
}
.tableModal__buttonGroup .tableModal__delete-form {
  margin: 10px;
}
.tableModal__buttonGroup .tableModal__saveBtn {
  display: inline;
  margin: 10px;
}
.table-fields {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.add-field,
.add-field__row {
  display: flex;
}
.add-field .add-field__row__input,
.add-field__row .add-field__row__input {
  width: 50%;
  margin-left: 10px;
}
.add-field__delete {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.loader {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
}
.table-warning {
  color: #c7133e;
  font-size: 14px;
}
.modal__button-set {
  display: flex;
  justify-content: flex-end;
}
.modal__button-set button {
  margin-left: 16px;
}
