.publishShareModal {
  text-align: center;
}
.publishShareModal :global .modal-header img {
  margin: 0 auto;
  margin-bottom: 20px;
}
.publishShareModal :global .modal-header h2 {
  margin: 0;
}
.publishShareModal :global .modal-body .body-text,
.publishShareModal :global .modal-body .actions,
.publishShareModal :global .modal-body .footer-text {
  margin-bottom: 20px;
}
.publishShareModal :global .modal-body .body-text {
  font-size: 16px;
}
.publishShareModal :global .modal-body .body-text .semibold {
  color: black;
  font-size: 18px;
}
.publishShareModal :global .modal-body .actions #share-modal-url-copy {
  margin-bottom: 0;
}
@media (max-width: 452px) {
  .publishShareModal :global .modal-body .actions #share-modal-url-copy {
    margin-bottom: 15px;
  }
}
.publishShareModal :global .modal-body .actions .btn {
  width: 200px;
}
.publishShareModal :global .modal-body .footer-text {
  font-size: 14px;
  margin-bottom: 15px;
}
.publishShareModal :global .modal-body .footer-text .text-button {
  margin-left: 5px;
}
.publishShareModal#publish-rsvp-modal :global .modal-header img {
  width: 100px;
}
@media (min-width: 768px) {
  .publishShareModal#publish-rsvp-modal :global .modal-body .body-text {
    margin: 0 auto;
    margin-bottom: 20px;
    width: 80%;
  }
}
