@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
#hide-pages-modalv2 .modal-header {
  padding: 0 20px 0 20px;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .modal-header {
    padding: 0 30px 0 30px;
  }
}
#hide-pages-modalv2 .modal-header h1,
#hide-pages-modalv2 .modal-header p {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 30px;
}
#hide-pages-modalv2 .modal-header p {
  color: #505050;
  font-size: 16px;
}
#hide-pages-modalv2 .modal-header img {
  margin: 0 auto;
  margin-bottom: 20px;
}
#hide-pages-modalv2 .modal-header h3 {
  font-size: 24px;
  margin: 0;
}
#hide-pages-modalv2 .modal-header .align-left {
  text-align: left;
}
#hide-pages-modalv2 .shipping-form-container {
  padding: 0 15px 0 15px;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .shipping-form-container {
    padding: 0 30px 0 30px;
  }
}
#hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 {
  max-width: 800px;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .shipping-field-container {
    padding-right: 80px;
  }
}
#hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .shipping-field-container .last-row .phone-number-field {
  width: 30%;
}
#hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .wrapping-button-container {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .wrapping-button-container {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-end;
  }
}
#hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .wrapping-button-container .btn {
  border-radius: 22.5px;
  height: 45px;
  max-width: 100%;
  width: 290px;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .wrapping-button-container .btn {
    border-radius: 20px;
    height: 40px;
    min-width: 200px;
    width: inherit;
  }
}
#hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .wrapping-button-container .skip-publish-registry {
  margin-bottom: 30px;
  margin-right: 0;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .wrapping-button-container .skip-publish-registry {
    margin-right: 10px;
  }
}
#hide-pages-modalv2 .shipping-form-container .shipping-address-form-v2 .wrapping-button-container .form-group {
  margin-bottom: 10px;
}
#hide-pages-modalv2 .modal-body {
  padding: 0;
  padding-top: 20px;
}
#hide-pages-modalv2 .modal-body .instructions {
  color: #7f7f7f;
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
#hide-pages-modalv2 .modal-body .instructions.bottom-border {
  border-bottom: solid 1px #d9d9d9;
}
#hide-pages-modalv2 .modal-body .hideable-pages {
  padding: 0 20px 30px 20px;
}
@media (min-width: 480px) {
  #hide-pages-modalv2 .modal-body .hideable-pages {
    padding: 0 40px 30px 40px;
  }
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item:first-of-type {
  border-top: solid 1px #d9d9d9;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item {
  align-items: center;
  background-color: #fcfcfc;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-info {
  align-items: center;
  display: flex;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-info .zolaicon {
  font-size: 40px;
  padding-right: 15px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-info .title {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .rsvp-text {
  padding-left: 82px;
  width: 100%;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle {
  height: 24px;
  padding-right: 20px;
  position: relative;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .with-tooltip {
  position: relative;
  z-index: 9999;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle {
  width: 46px;
  z-index: 9999;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-track {
  width: 46px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-thumb {
  left: 2px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle.react-toggle--checked .react-toggle-thumb {
  border-color: #0e0e0e;
  left: 23px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-track-check {
  height: 15px;
  left: 5px;
  top: 4px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-track-check span {
  color: white;
  font-size: 12px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-track-check span::before {
  font-weight: 900;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-track-x {
  height: 18px;
  right: 2px;
  top: 2px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-track-x span {
  color: white;
  font-size: 16px;
}
#hide-pages-modalv2 .modal-body .hideable-pages .hideable-pages-list .hideable-item .hide-page-toggle .react-toggle .react-toggle-track-x span::before {
  font-weight: 900;
}
#hide-pages-modalv2 .modal-footer {
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
#hide-pages-modalv2 .modal-footer .modal-actions {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .modal-footer .modal-actions {
    flex-direction: row;
  }
}
#hide-pages-modalv2 .modal-footer .modal-actions .btn {
  border-radius: 22.5px;
  height: 45px;
  margin: 0;
  width: 290px;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .modal-footer .modal-actions .btn {
    height: 40px;
  }
}
#hide-pages-modalv2 .modal-footer .modal-actions .hide-btn-cancel {
  margin-top: 10px;
}
@media (min-width: 768px) {
  #hide-pages-modalv2 .modal-footer .modal-actions .hide-btn-cancel {
    margin-right: 10px;
    margin-top: 0;
    width: 200px;
  }
  #hide-pages-modalv2 .modal-footer .modal-actions .hide-btn-cta {
    width: 240px;
  }
}
#hide-pages-modalv2 .registry-visibility .modal-header {
  text-align: center;
}
#hide-pages-modalv2 .registry-visibility .modal-header h3 {
  text-align: left;
}
#hide-pages-modalv2 .registry-visibility.has-shipping-address .modal-header {
  text-align: center;
}
#hide-pages-modalv2 .registry-visibility.has-shipping-address .modal-header h3 {
  text-align: center;
}
#hide-pages-modalv2 .registry-visibility .modal-body .instructions {
  padding-bottom: 30px;
}
#hide-pages-modalv2 .registry-visibility .modal-actions button {
  width: 200px;
}
