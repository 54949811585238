.cardListingWrapper {
  max-width: 1170px;
}
@media (min-width: 992px) {
  .cardListingWrapper {
    margin: 0 auto;
  }
}
.suites {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 24px;
}
@media (min-width: 992px) {
  .suites {
    padding: 0 40px;
  }
}
.suitesActions {
  align-items: center;
  display: flex;
  justify-content: center;
}
.divider {
  background-color: #d9d9d9;
  height: 2px;
  margin-bottom: 20px;
  width: 100%;
}
.blueBg {
  background-color: #F2F5FB;
}
.lowercase {
  text-transform: none !important;
}
.loaderAnchor {
  align-content: flex-start;
  background-color: #fff;
  display: block;
  flex-wrap: wrap;
  min-height: calc(100vh - 164px);
  position: relative;
}
@media (max-width: 480px) {
  .loaderAnchor {
    min-height: calc(100vh - 142px);
  }
}
.loaderContainer {
  align-content: flex-start;
  align-items: center;
  bottom: -2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  min-height: calc(100vh - 164px);
  position: absolute;
  right: 0;
  top: -16px;
  z-index: 2;
}
@media (max-width: 480px) {
  .loaderContainer {
    min-height: calc(100vh - 142px);
  }
}
.cardListingFilterContainer {
  min-height: 44px;
}
