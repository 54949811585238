.matchingItemsPanel {
  font-size: 16px;
  line-height: 24px;
  margin: 32px 0;
}
.matchingItemsTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
.matchingItemsTitleV2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.matchingItemsWrapper {
  margin-top: 12px;
}
.matchingItemPreviewModal {
  width: 568px;
}
.matchingItemWrapper {
  border-bottom: 1px solid #d9d9d9;
  color: #505050;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 32px;
  padding: 8px 8px 8px 16px;
}
.matchingItemWrapper:last-child {
  border-bottom: none;
}
.matchingItemWrapper:hover .matchingItemType {
  color: #4a4a4a;
}
.matchingItemType {
  align-items: center;
  display: flex;
  position: relative;
  text-decoration: underline;
  color: #0e0e0e;
}
.tooltip::before {
  border-color: #505050;
}
.tooltip .tooltipContent {
  background-color: #505050;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 12px;
  width: 151px;
}
.draftsConfirmation {
  color: #15845d;
  cursor: pointer;
}
.draftsConfirmationHover {
  color: #0075ae;
  cursor: pointer;
  text-align: right;
  width: 175px;
}
.checkmark {
  margin-right: 8px;
}
.addDraftCTA {
  background-color: #eeeeee;
  border-radius: 4px;
  cursor: pointer;
  line-height: 24px;
  max-height: 32px;
  padding: 4px 12px;
}
.plusIcon {
  height: 12px !important;
  margin-right: 8px;
  width: 12px !important;
}
