@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.scroll-select {
  display: block;
  position: relative;
}
.scroll-select.with-tooltip {
  display: block;
  font-weight: 400;
}
.scroll-select.inline {
  display: inline-block;
}
.scroll-select:focus {
  outline: none;
}
.scroll-select:focus .btn-dropdown {
  border-color: #0e0e0e;
}
.scroll-select.has-error .btn-dropdown {
  border-color: #c7305b;
}
.scroll-select .btn {
  line-height: 1.2;
  text-align: left;
}
.scroll-select .btn.btn-dropdown {
  width: 100%;
}
.scroll-select .btn-dropdown {
  width: 100%;
  border: 1px solid #757575;
}
.scroll-select .btn-dropdown .zolaicon-chevron-down,
.scroll-select .btn-dropdown .zolaicon-chevron-up {
  position: absolute;
  right: 10px;
}
.scroll-select .scroll-value {
  color: black;
  display: inline-block;
  width: calc(90%);
  overflow: hidden;
  text-overflow: ellipsis;
}
.scroll-select .scroll-select-dropdown {
  position: absolute;
  width: 100%;
  z-index: 50;
  margin: 8px 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow-x: auto;
  overflow-y: scroll;
  max-height: 260px;
  background-color: #ffffff;
  cursor: pointer;
}
.scroll-select .scroll-select-dropdown .scroll-select-option {
  width: 100%;
  padding: 10px 15px;
}
.scroll-select .scroll-select-dropdown .scroll-select-option.scroll-select-active {
  background-color: #f3f3f3;
}
.scroll-select#address-state .scroll-select-dropdown {
  max-height: 360px;
  overflow-y: auto;
}
.event-form .event-address {
  margin-bottom: 32px;
}
.event-form .event-address select.form-control {
  border: 0;
  outline: 1px solid #b7b7b7;
  outline-offset: -1px;
}
.switch-field {
  line-height: 10px;
  overflow: hidden;
  display: inline-block;
  margin: 0;
}
.switch-field .switch-field-input-container {
  height: 38px;
}
@media (max-width: 768px) {
  .switch-field .switch-field-input-container {
    height: 50px;
  }
}
.switch-field:first-child label {
  border-left: 1px solid #0e0e0e;
}
.switch-field:last-child label {
  border-right: 1px solid #0e0e0e;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field input:checked + label {
  color: #ffffff;
  background-color: #0e0e0e;
  -webkit-box-shadow: none;
}
.switch-field label {
  float: left;
  display: inline-block;
  width: 100%;
  background-color: #ffffff;
  color: #0e0e0e;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  text-shadow: none;
  line-height: 34px;
  border: 1px solid #0e0e0e;
  border-left: 1px solid #0e0e0e;
  border-right: 1px solid #0e0e0e;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.switch-field label:hover {
  cursor: pointer;
}
