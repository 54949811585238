.samplePreview {
  width: 274px;
  height: 274px;
  padding: 20px;
}
.cardName {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 16px 0;
}
.spaceHolder {
  margin-bottom: 20px;
}
.digitalSamplePreview {
  width: 219px;
  height: 219px;
  padding: 20px;
}
@media (max-width: 991px) {
  .digitalSamplePreview {
    width: 200px;
    height: 200px;
    padding: 10px;
  }
}
.paperSamplePreview {
  width: 259px;
  height: 259px;
  padding: 20px;
}
