.recentlyViewedSection {
  background-color: #f9f9f9;
  border-bottom: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
  margin-top: 40px;
  text-align: center;
}
.container {
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
  padding: 32px 20px;
  width: 100%;
  max-width: 1280px;
}
@media (max-width: 480px) {
  .container {
    padding: 32px 0;
  }
  .container .title {
    padding: 0 24px;
  }
  .container .recentlyViewedCarousel {
    padding: 0 16px;
  }
}
@media (min-width: 992px) {
  .container {
    padding: 40px 16px;
  }
}
.container .recentlyViewedCarousel {
  padding: 0 16px;
}
@media (min-width: 767px) {
  .container .recentlyViewedCarousel :global .slick-next {
    height: 44px;
    width: 44px;
  }
  .container .recentlyViewedCarousel :global .slick-prev {
    height: 44px;
    width: 44px;
  }
}
@media (min-width: 1439px) {
  .container .recentlyViewedCarousel :global .slick-next {
    right: -55px !important;
  }
  .container .recentlyViewedCarousel :global .slick-prev {
    left: -55px !important;
  }
}
@media (max-width: 767px) {
  .container .recentlyViewedCarousel :global .slick-next {
    right: 0 !important;
  }
  .container .recentlyViewedCarousel :global .slick-prev {
    z-index: 1;
    left: 0 !important;
  }
}
.title {
  color: #0e0e0e;
  display: block;
  font-family: 'circular', helvetica, sans-serif;
  font-weight: 400;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 28px;
}
@media (min-width: 481px) {
  .title {
    font-size: 32px;
    line-height: 36px;
  }
}
.title .titleBold {
  font-weight: 600;
}
.recentlyViewedCard {
  border-radius: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin: 0 10px 6px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
.recentlyViewedCard img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
}
.carouselArrow {
  border: 0;
  border-radius: 35px;
  color: #0075ae;
  cursor: pointer;
  opacity: 1;
  outline: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
  z-index: 200;
}
@media (max-width: 480px) {
  .carouselArrow {
    display: none;
  }
}
@media (max-width: 767px) {
  .carouselArrow {
    font-size: 14px;
  }
}
.carouselArrow.carouselArrowLeft {
  left: -40px;
}
.carouselArrow.carouselArrowRight {
  right: -40px;
}
.carouselArrow.carouselArrowDisabled {
  color: #d9d9d9;
}
.noBottomBorder {
  border-bottom: none;
}
.isPDP {
  margin: 0;
  border-top: none;
}
.isPDP .title {
  font-size: 24px;
  letter-spacing: -0.3px;
}
