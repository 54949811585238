.tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  border-radius: 4px;
  color: #505050;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 16px;
  padding: 4px 8px;
}
@media (max-width: 767px) {
  .tag {
    font-size: 12px;
  }
}
.tag > i {
  color: #15845d;
  margin-right: 6px;
}
