.closeCustomModal {
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 1;
  padding: 40px;
}
@media (max-width: 767px) {
  .closeCustomModal {
    padding: 56px 24px 24px;
    align-items: flex-start;
  }
}
.details {
  margin: 24px 0 32px;
  font-size: 16px;
  line-height: 24px;
}
.buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
}
@media (max-width: 767px) {
  .buttons {
    flex-direction: column;
  }
}
