@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg);
  }
  10% {
    transform: rotateY(7deg);
  }
  40% {
    transform: rotateY(7deg);
  }
  60% {
    transform: rotateY(-7deg);
  }
  90% {
    transform: rotateY(-7deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes foldedPortraitRotateAnimation {
  0% {
    transform: rotateX(-7deg) rotateY(0deg);
  }
  10% {
    transform: rotateX(-7deg) rotateY(7deg);
  }
  40% {
    transform: rotateX(-7deg) rotateY(7deg);
  }
  60% {
    transform: rotateX(-7deg) rotateY(-7deg);
  }
  90% {
    transform: rotateX(-7deg) rotateY(-7deg);
  }
  100% {
    transform: rotateX(-7deg) rotateY(0deg);
  }
}
@keyframes shadowAnimation {
  0% {
    background-position: 0% 0%;
  }
  10% {
    background-position: 100% 0%;
  }
  40% {
    background-position: 100% 0%;
  }
  60% {
    background-position: 0% 0%;
  }
  90% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes foilAnimation {
  0% {
    background-position: 0% 0%;
  }
  10% {
    background-position: 50% 0%;
  }
  40% {
    background-position: 50% 0%;
  }
  60% {
    background-position: -50% 0%;
  }
  90% {
    background-position: -50% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes letterpressAnimation {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  40% {
    opacity: 0.9;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes letterpressImageAnimation {
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.4;
  }
}
.previewBox {
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}
.previewBoxAnimation {
  perspective: 1500px;
  transition: perspective-origin 500ms, perspective-origin-x 500ms, perspective-origin-y 500ms;
}
.cardWrapper,
.card {
  transform-style: preserve-3d;
}
.cardSide {
  backface-visibility: hidden;
  background: #ffffff;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  clip-path: url(#svgClip);
}
/* Flat / non-folded cards */
.previewBoxFlat {
  perspective-origin: center;
}
.previewBoxFlat .card {
  filter: drop-shadow(-32px 16px 24px rgba(51, 21, 0, 0.15)) drop-shadow(-8px 4px 16px rgba(51, 21, 0, 0.25));
  /* Safari support */
}
@supports (-webkit-hyphens: none) {
  .previewBoxFlat .card {
    filter: drop-shadow(-8px 4px 16px rgba(51, 21, 0, 0.25));
  }
}
.previewBoxFlat .cardAnimation {
  animation: rotateAnimation 7s linear infinite;
}
/* Folded landscape cards */
.previewBoxFoldedLandscape {
  perspective-origin: right;
}
.previewBoxFoldedLandscape .card {
  transform: rotateY(-7deg);
}
.previewBoxFoldedLandscape .cardAnimation {
  animation: rotateAnimation 7s linear infinite;
}
.previewBoxFoldedLandscape .cardSideFront {
  filter: drop-shadow(-16px 32px 24px rgba(51, 21, 0, 0.15)) drop-shadow(0px 16px 16px rgba(51, 21, 0, 0.25));
  /* Safari support */
  clip-path: none;
  transform-origin: top;
}
@supports (-webkit-hyphens: none) {
  .previewBoxFoldedLandscape .cardSideFront {
    filter: drop-shadow(0px 16px 16px rgba(51, 21, 0, 0.25));
  }
}
.previewBoxFoldedLandscape .cardSideInsideRight {
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
  clip-path: none;
  transform: rotateX(-22deg);
  transform-origin: top;
}
/* Folded portrait cards */
.previewBoxFoldedPortrait {
  perspective-origin: top;
}
.previewBoxFoldedPortrait .card {
  transform: rotateX(-7deg);
}
.previewBoxFoldedPortrait .cardAnimation {
  animation: foldedPortraitRotateAnimation 7s linear infinite;
}
.previewBoxFoldedPortrait .cardSideFront {
  filter: drop-shadow(32px 0px 24px rgba(51, 21, 0, 0.15)) drop-shadow(8px 4px 8px rgba(51, 21, 0, 0.25));
  /* Safari support */
  clip-path: none;
  transform-origin: left;
}
@supports (-webkit-hyphens: none) {
  .previewBoxFoldedPortrait .cardSideFront {
    filter: drop-shadow(8px 4px 8px rgba(51, 21, 0, 0.25));
  }
}
.previewBoxFoldedPortrait .cardSideInsideTop {
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.25));
  clip-path: none;
  transform: rotateY(17deg);
  transform-origin: left;
}
/* Additional animation as card rotates */
.cardAnimation {
  /* Casts shadow on front of card */
  /* Applies shimmer effect to foil text */
  /**
   * Applies shimmer effect to foil text.
   * Note, CSS mask-image property triggers CORS issue because it needs to process the image's pixel data,
   * whereas background-image simply displays the image. This means shimmer effect will not show up locally.
   */
  /* Applies shadow effect to letterpress text */
  /* Applies shadow effect to letterpress image */
}
.cardAnimation .cardSideFront::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.2));
  background-size: 200% 100%;
  animation: shadowAnimation 7s linear infinite;
  z-index: 10;
}
.cardAnimation .foil {
  animation: foilAnimation 7s linear infinite;
  background-size: 50% 100%;
}
.cardAnimation .foilImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2));
  animation: foilAnimation 7s linear infinite;
  background-size: 50% 100%;
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: center;
  mask-composite: exclude;
}
.cardAnimation .letterpress {
  animation: letterpressAnimation 7s linear infinite;
}
.cardAnimation .letterpressImage {
  animation: letterpressImageAnimation 7s linear infinite;
}
/* Disables animation for devices configured to minimize motion */
@media (prefers-reduced-motion) {
  .cardAnimation,
  .cardSideFront::after,
  .foil,
  .foilImage,
  .letterpress,
  .letterpressImage {
    animation: none !important;
  }
  .playerButton {
    display: none !important;
  }
}
.rotateSVG {
  transform: rotate(90deg);
  transform-box: fill-box;
  transform-origin: center;
}
.rotateSVG.flipSVG {
  transform: rotate(90deg) scaleX(-1);
}
.onlyBottomHalf {
  height: 100%;
  left: 0;
  position: absolute;
  top: -100%;
  width: 100%;
}
.onlyRightHalf {
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.imageLayer .letterpressImage {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  position: absolute;
  left: 0.5px;
  top: -0.5px;
  opacity: 0.2;
}
.textLayer {
  display: inline-block;
  position: absolute;
  white-space: pre;
}
.textLayer .foil {
  /* clip-path gets rid of a 1px box outline on safari */
  clip-path: inset(1px);
  display: inline-block;
  /* The positioning + padding fixes a gradient cutoff with certain fonts */
  position: absolute;
  bottom: -4px;
  left: -4px;
  right: -4px;
  top: -4px;
  padding: 4px;
}
.textLayer .letterpress {
  text-shadow: 0px 1px 2px rgba(200, 200, 200, 0.8), 0.5px -0.5px 0.5px rgba(0, 0, 0, 0.6);
  opacity: 0.9;
}
.playerButton {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 44px;
  padding: 0 !important;
}
