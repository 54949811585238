/* Loader */
.zolaLoader {
  padding-top: 25px;
  margin: 0 auto;
  height: 125px;
  width: 20px;
  position: relative;
}
.zolaLoader .petal {
  position: absolute;
  width: 20px;
  height: 60px;
  background-size: auto 30px;
  background-repeat: no-repeat;
  animation: spin 1.5s infinite ease;
}
.zolaLoader .petal:nth-of-type(2) {
  animation-delay: 0.1s;
}
.zolaLoader .petal:nth-of-type(3) {
  animation-delay: 0.2s;
}
.zolaLoader .petal:nth-of-type(4) {
  animation-delay: 0.3s;
}
.zolaLoader .petal:nth-of-type(5) {
  animation-delay: 0.4s;
}
.zolaLoader .petal:nth-of-type(6) {
  animation-delay: 0.5s;
}
.zolaLoader .petal:nth-of-type(7) {
  animation-delay: 0.6s;
}
.zolaLoader .petal:nth-of-type(8) {
  animation-delay: 0.7s;
}
.zolaLoader .petal:nth-of-type(4n + 1) {
  background-image: url('https://d1tntvpcrzvon2.cloudfront.net/vwassets/new2/static/media/petal1.svg');
}
.zolaLoader .petal:nth-of-type(4n + 2) {
  background-image: url('https://d1tntvpcrzvon2.cloudfront.net/vwassets/new2/static/media/petal2.svg');
}
.zolaLoader .petal:nth-of-type(4n + 3) {
  background-image: url('https://d1tntvpcrzvon2.cloudfront.net/vwassets/new2/static/media/petal3.svg');
}
.zolaLoader .petal:nth-of-type(4n + 4) {
  background-image: url('https://d1tntvpcrzvon2.cloudfront.net/vwassets/new2/static/media/petal4.svg');
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
:global(.modernizr-no-cssanimations) .zolaLoader {
  margin: auto;
  width: 50px;
  height: 50px;
  background-image: url('https://d1tntvpcrzvon2.cloudfront.net/vwassets/new2/static/media/spinners/dark.gif');
}
