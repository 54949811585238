.container {
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  height: fit-content;
  min-height: fit-content;
  padding: 20px;
  gap: 12px;
}
.container:last-child {
  margin-bottom: 0;
}
.container:hover {
  border-color: #0075ae;
  cursor: pointer;
}
.container.active {
  border-color: #0075ae;
}
.customizationsContainer {
  margin-top: 12px;
}
.detailContainer {
  flex: 1 1 auto;
}
.detailContainer .header {
  color: #21201f;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
}
.detailContainer .lastUpdated {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}
.detailContainer .customization {
  align-items: center;
  color: #505050;
  display: flex;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
.detailContainer .customization b {
  color: #21201f;
  font-weight: 600;
}
.mainImage {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  max-width: 100px;
  max-height: 100px;
}
.previewWrapper {
  flex: 0 0 100px;
}
