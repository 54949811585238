/**
 * @prettier
 */
.loaderWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.drawerBody {
  min-height: 100vh;
  padding: 20px 20px 84px 20px;
}
@media (min-width: 768px) {
  .drawerBody {
    padding: 40px 40px 110px 40px;
  }
}
.drawerButtons {
  position: fixed;
  bottom: 0;
  padding: 20px 40px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .drawerButtons {
    width: 622px;
  }
}
.drawerTitle {
  color: #21201f;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.13;
}
.drawerSubtitle {
  color: #505050;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: normal;
  margin-bottom: 22px;
}
