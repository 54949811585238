.enhanceRegistry .backLink {
  position: absolute;
  top: 28px;
  left: 40px;
  font-size: 16px;
}
.enhanceRegistry .title {
  margin-bottom: 40px;
  text-align: center;
}
.enhanceRegistry .tiles {
  display: grid;
  grid-template-columns: repeat(10, 50px);
  row-gap: 40px;
  margin: 0 auto;
  width: fit-content;
  column-gap: 32px;
}
.enhanceRegistry .tile {
  text-decoration: none;
  text-align: center;
  grid-column: span 2;
}
.enhanceRegistry .tile:hover,
.enhanceRegistry .tile:focus {
  text-decoration: underline;
}
.enhanceRegistry .tile:nth-child(6) {
  grid-column: 2 / span 2;
}
.enhanceRegistry .tile:nth-child(7) {
  grid-column: 4 / span 2;
}
.enhanceRegistry .tile:nth-child(8) {
  grid-column: 6 / span 2;
}
.enhanceRegistry .tile:nth-child(9) {
  grid-column: 8 / span 2;
}
.enhanceRegistry .tile p {
  font-weight: 600;
  margin-top: 16px;
}
.enhanceRegistry .tileImage {
  border-radius: 16px;
  width: 132px;
  height: 132px;
}
.enhanceRegistry .footer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 40px;
  border-top: 1px solid #b7b7b7;
}
.enhanceRegistry .doneButton {
  width: 240px;
}
