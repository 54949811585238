@import "~react-image-crop/dist/ReactCrop.css";
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.modal .crop-wrapper {
  text-align: center;
}
.modal .photo-preview {
  width: 300px;
  height: 300px;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border: solid 1px #d9d9d9;
}
.modal .save-crop {
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
.modal .save-button {
  padding: 10px 0;
  float: right;
}
