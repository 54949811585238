@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.text-layer {
  /* These z-indexes are stacked inside .text-layer */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  user-select: none;
  width: 100%;
  height: 100%;
  /* Field doesn't let us pass a class to the textarea */
}
.text-layer.selected {
  background: rgba(249, 249, 249, 0.1);
}
.text-layer.highlight-error {
  background: rgba(254, 226, 229, 0.4);
}
.text-layer.highlight-error.selected {
  background: rgba(254, 226, 229, 0.3);
}
.text-layer.selectable {
  inset: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 1px dashed transparent;
}
.text-layer.selectable:not(.selected):hover {
  border-color: #55a1bf;
}
.text-layer.selectable:not(.selected):hover.highlight-error {
  border-color: #c7305b;
}
.text-layer .text {
  bottom: 0;
  display: inline-block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 0;
  white-space: pre;
  z-index: 3;
}
.text-layer .text.editing {
  z-index: 1;
}
.text-layer .text-element {
  display: inline-block;
  padding-top: 0;
  z-index: 1;
}
.text-layer .text-foil-overflow {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: -1;
  padding-top: 0;
}
.text-layer .text-foil-overflow.alignTop {
  top: 0;
}
.text-layer .text-foil-overflow.alignMiddle {
  top: 50%;
  transform: translateY(-50%);
}
.text-layer .textarea-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: visible;
  z-index: 2;
}
.text-layer textarea {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  padding: 0;
  resize: none;
  /* remove the resize handle on the bottom right */
  white-space: pre;
  width: 100%;
}
