@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.confirm-exit__modal {
  background: rgba(255, 255, 255, 0.9);
  color: #21201f;
  font-family: 'circular', serif;
  height: 100%;
  left: 0;
  padding: 40px 20px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .confirm-exit__modal {
    padding-bottom: 90px;
    padding-top: 90px;
  }
}
.confirm-exit__modal p,
.confirm-exit__modal h3 {
  color: inherit;
  font-family: inherit;
  margin: 0;
}
.confirm-exit__modal .confirm-exit__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
}
.confirm-exit__modal .confirm-exit__title {
  font-size: 28px;
  font-weight: 400;
  line-height: 1.2;
}
.confirm-exit__modal .confirm-exit__intro {
  font-size: 18px;
  line-height: 1.5;
}
.confirm-exit__modal .confirm-exit__buttons,
.confirm-exit__modal .confirm-exit__intro {
  margin-top: 24px;
}
.confirm-exit__modal .confirm-exit__buttons {
  align-items: center;
  display: flex;
  justify-content: center;
}
.confirm-exit__modal .confirm-exit__ok,
.confirm-exit__modal .confirm-exit__no {
  max-width: 150px;
  width: 50%;
}
.confirm-exit__modal .confirm-exit__ok {
  margin-right: 24px;
}
