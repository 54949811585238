.container {
  align-items: center;
  background-color: #e5eef7;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.container.v3container {
  background-color: #f3f3f3;
}
.container.v3container h1 {
  font-family: 'circular', helvetica, sans-serif;
}
.appImage {
  padding: 0 20px;
  width: 100%;
}
.modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow-y: hidden;
}
.modalParent {
  height: 100vh;
  overflow: hidden;
}
.hideForModal {
  display: none;
}
