@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
img {
  max-width: 100%;
}
.primary-color {
  color: #0e0e0e;
}
.bold {
  font-weight: 400;
}
.std-mandril-placeholder {
  display: block;
  background-size: 100% 1400px;
  height: 1400px;
  width: 100%;
  background-image: url('../../assets/images/invitations/static-digital-std@2x.png');
  background-repeat: no-repeat;
}
.modernizr-no-svg .std-mandril-placeholder {
  background-image: url('../../assets/images/invitations/static-digital-std@2x.png');
}
.invitations-container {
  padding-left: 0;
  padding-right: 0;
  background: #f7f7f7;
}
.invitations-container .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.invitations .menu-container-v2 {
  margin-left: -10px;
  margin-right: -10px;
}
.disable-look {
  color: #d9d9d9;
}
.error-arrow {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
}
.uploadcare--dragging {
  cursor: grabbing;
}
.uploadcare--file-sources__items .uploadcare--button.uploadcare--file-source_all {
  display: none;
}
