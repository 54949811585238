.websiteInstantRegistryModal .closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  background-color: transparent;
  color: #0e0e0e;
}
.websiteInstantRegistryModal .closeButton:hover {
  color: #4a4a4a;
}
.websiteInstantRegistryModal :global #instant-registry-products {
  min-height: 100vh;
}
.websiteInstantRegistryModal :global #instant-registry-footer {
  position: sticky;
  bottom: 0;
  margin-left: -40px;
  margin-right: -40px;
  padding-left: 40px;
  padding-right: 40px;
}
.websiteInstantRegistryModal :global #instant-registry-footer .instant-registry-add-button {
  width: 240px;
  height: 44px;
}
:global .website-instant-registry-modal {
  width: 1024px !important;
  max-width: 100%;
  margin-top: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  min-height: calc(100vh - 24px);
  min-height: calc(100dvh - 24px);
}
:global .website-instant-registry-modal.animate {
  transition: margin-top 0.5s 0.2s ease;
}
:global .website-instant-registry-modal.slide-in {
  margin-top: 24px;
}
:global .website-instant-registry-modal .modal-content {
  padding: 48px 40px 0;
  border-radius: 16px 16px 0 0;
  background-color: #ffffff;
  min-height: calc(100vh - 24px);
  min-height: calc(100dvh - 24px);
}
.hideInstantRegistry {
  display: none;
}
