.zui__datepicker .DayPickerInput-Overlay {
  margin-top: 8px;
  width: 275px;
  z-index: 5;
  box-shadow: none;
}
.zui__datepicker .DayPicker {
  color: #21201f;
  font-size: 16px;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.zui__datepicker .DayPicker-NavButton {
  top: 19px;
}
.zui__datepicker .DayPicker-NavButton--prev {
  right: 50px;
}
.zui__datepicker .DayPicker-NavButton--next {
  right: 20px;
}
.zui__datepicker .DayPicker-Weekday {
  padding-left: 0;
  padding-right: 0;
  color: #aaaaaa;
  font-weight: 400;
}
.zui__datepicker .DayPicker-Day {
  width: 35px;
  height: 35px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  outline: none;
}
.zui__datepicker .DayPicker-Day:hover {
  background-color: #f5f7fa !important;
}
.zui__datepicker .DayPicker-Day--today {
  color: #21201f;
  font-weight: 400;
}
.zui__datepicker .DayPicker-Day--outside {
  color: #d9d9d9;
}
.zui__datepicker .DayPicker-Day--selected,
.zui__datepicker .DayPicker-Day--selected:hover {
  color: #ffffff !important;
  background-color: #003b58 !important;
}
