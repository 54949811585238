@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.image-layer-container {
  overflow: visible;
  position: absolute;
  /* Prevent pointer events on non-customizable images so we don't block the hover state of other elements the image overlaps */
}
.image-layer-container .element-drag-handle {
  align-items: center;
  background: #55a1bf;
  border-radius: 100%;
  color: #ffffff;
  cursor: move;
  display: flex;
  height: 28px;
  justify-content: center;
  left: calc(50% - 14px);
  position: absolute;
  top: -36px;
  width: 28px;
  z-index: 1000;
}
.image-layer-container .element-resize-handle {
  display: none;
}
.image-layer-container:not(.isSelectable) {
  pointer-events: none;
  touch-action: none;
}
.image-layer-container .image-layer-preview {
  left: 0;
  position: absolute;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.image-layer-container .image-layer-preview.stretch {
  height: 100%;
  width: 100%;
}
.image-layer-container .placeholder-image {
  background: #f3f3f3;
  height: 100%;
  position: absolute;
  width: 100%;
}
.image-layer-container .image-layer-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.image-layer-container .image-layer-loading-overlay {
  align-items: center;
  background-color: #f3f3f3;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.image-layer-container .coordinate-line {
  display: flex;
  position: absolute;
  z-index: 10;
}
.image-layer-container .coordinate-line .coordinate-line__value {
  align-items: center;
  background-color: white;
  background-color: rgba(51, 96, 129, 0.55);
  color: white;
  display: flex;
  font-family: 'circular', serif;
  font-size: 12px;
  height: 15px;
  letter-spacing: normal;
  line-height: normal;
  padding-left: 3px;
  padding-right: 3px;
}
.image-layer-container .coordinate-line.coordinate-line--vertical {
  align-items: center;
  border-left: 1px solid #336081;
  width: 1px;
}
.image-layer-container .coordinate-line.coordinate-line--horizontal {
  border-bottom: 1px solid #336081;
  height: 1px;
  justify-content: center;
}
.image-layer-container .coordinate-line.coordinate-line--horizontal .coordinate-line__value {
  bottom: 0;
  position: absolute;
}
