.apPdpPage {
  align-items: center;
  background-color: #d9d9d9;
  border-radius: 4px;
  display: flex;
  margin: 50px 0;
}
@media (max-width: 991px) {
  .apPdpPage {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1199px) {
  .apPdpPage {
    flex-direction: column;
    align-items: flex-start;
  }
}
.apPdpPage .apPdpPageImgContainer {
  width: 100%;
  display: flex;
}
@media (min-width: 1200px) {
  .apPdpPage .apPdpPageImgContainer {
    max-width: 50%;
  }
}
.apPdpPage .apPdpPageImgContainer img {
  width: 100%;
  border-radius: 4px 0 0 4px;
  object-fit: cover;
}
@media (max-width: 1199px) {
  .apPdpPage .apPdpPageImgContainer img {
    border-radius: 4px 4px 0 0;
  }
}
.apPdpPage .apPdpPageTextContainer {
  max-width: 260px;
  padding: 12px 20px;
}
@media (max-width: 1199px) {
  .apPdpPage .apPdpPageTextContainer {
    max-width: none;
    padding: 20px;
  }
}
.apPdpPage .apPdpPageTitle {
  font-size: 20px;
  line-height: 24px;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  color: #21201f;
  text-align: left;
  margin-bottom: 8px;
}
@media (max-width: 480px) {
  .apPdpPage .apPdpPageTitle {
    max-width: 270px;
  }
}
@media (max-width: 767px) {
  .apPdpPage .apPdpPageTitle {
    font-size: 24px;
  }
}
.apPdpPage .apPdpPageText {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: left;
}
@media (max-width: 991px) {
  .apPdpPage .apPdpPageText {
    margin-top: 20px;
  }
}
