/* stylelint-disable selector-pseudo-class-no-unknown, declaration-no-important */
.filterBtnContainer {
  position: relative;
}
.filterBtnContainer a {
  text-decoration: none;
}
.filterBtnContainer :global .dropdown {
  display: block;
}
.filterBtnContainer :global .dropdown__content {
  display: none;
  position: absolute;
}
.filterBtnContainer :global .dropdown--active .dropdown__content {
  display: block;
}
.filterBtnContainer :global .dropdown__trigger:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  .newBadge {
    padding: 10px 16px !important;
  }
}
.newBadge::after {
  background: #15845d;
  border-radius: 5px;
  color: #ffffff;
  content: 'New';
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  line-height: 14px;
  margin-left: 8px;
  padding: 3px 7px;
  position: relative;
}
.dropdownContainer {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  left: 4px;
  margin-top: 0;
  position: absolute;
  width: 280px;
  z-index: 10;
}
.dropdownContainerWide {
  width: 400px;
}
