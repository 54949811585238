.websiteTile {
  width: 220px;
}
@media (max-width: 767px) {
  .websiteTile {
    width: 145px;
  }
}
.image {
  width: 220px;
  height: 220px;
  background-size: cover;
  border-radius: 4px;
  position: relative;
}
@media (max-width: 767px) {
  .image {
    width: 145px;
    height: 145px;
  }
}
