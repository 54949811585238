@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.modal-dialog {
  margin: 100px auto;
}
.tip-content-container {
  padding: 0 35px;
}
.tip-content-container .modal-header {
  padding: 30px 20px;
}
.tip-content-container .modal-header .tip-header {
  border-bottom: 1px solid #d9d9d9;
  color: #4A4A4A;
  font-family: 'circular', serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.tip-content-container .modal-body {
  padding: 40px 20px 30px;
}
.tip-content-container .modal-body h3 {
  margin: 10px 0;
  padding: 0;
}
.tip-content-container .modal-body .head {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 380;
  margin: 10px 0 0;
}
.tip-content-container .modal-body .desc {
  color: #4a4a4a;
  font-size: 16px;
  line-height: 26px;
  padding: 5px 0;
}
.tip-content-container .modal-body ul.desc {
  margin-left: 40px;
}
.tip-content-container .modal-body ul.desc li {
  color: #4a4a4a;
  font-size: 16px;
  line-height: 18px;
  padding: 5px 0;
}
.tip-content-container .modal-body .border-box {
  border: 1px solid #d9d9d9;
  height: 100px;
  margin-top: 15px;
  position: relative;
  width: 400px;
}
.tip-content-container .modal-body .border-box .box-returnaddress {
  color: #7f7f7f;
  font-size: 12px;
  left: 5px;
  position: absolute;
  top: 2px;
}
.tip-content-container .modal-body .border-box .box-postage {
  color: #7f7f7f;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 2px;
}
.tip-content-container .modal-body .border-box .box-recipient {
  color: #7f7f7f;
  font-size: 12px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
