:global .digital-greeting-card-modal {
  width: 1024px !important;
  max-width: 100%;
  border-radius: 24px;
  overflow: hidden;
}
@media (max-width: 767px) {
  :global .digital-greeting-card-modal {
    max-height: 100dvh;
    margin: 0 !important;
    border-radius: 0;
    overflow: scroll;
  }
}
:global .digital-greeting-card-modal .modal-content {
  padding: 48px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  :global .digital-greeting-card-modal .modal-content {
    min-height: 100dvh;
    padding: 48px 24px;
    border-radius: 0;
  }
}
:global .digital-greeting-card-modal .modal-close {
  display: none;
}
:global .quick-checkout-modal {
  width: 536px !important;
  max-width: 100%;
  border-radius: 24px;
  overflow: hidden;
}
@media (max-width: 767px) {
  :global .quick-checkout-modal {
    max-height: 100dvh;
    margin: 0 !important;
    border-radius: 0;
    overflow: scroll;
  }
}
:global .quick-checkout-modal .modal-content {
  padding: 48px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  :global .quick-checkout-modal .modal-content {
    min-height: 100dvh;
    padding: 48px 24px;
    border-radius: 0;
  }
}
:global .quick-checkout-modal .modal-close {
  top: 16px;
  right: 16px;
  color: #0e0e0e;
}
:global .quick-checkout-modal .modal-close:hover {
  color: #4a4a4a;
}
:global .registry-product-purchased-modal {
  width: 536px !important;
  max-width: 100%;
  border-radius: 24px;
  overflow: hidden;
}
@media (max-width: 767px) {
  :global .registry-product-purchased-modal {
    max-height: 100dvh;
    margin: 0 !important;
    border-radius: 0;
    overflow: scroll;
  }
}
:global .registry-product-purchased-modal .modal-content {
  padding: 72px 48px 48px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  :global .registry-product-purchased-modal .modal-content {
    min-height: 100dvh;
    padding: 48px 24px;
    border-radius: 0;
  }
}
:global .registry-product-purchased-modal .modal-close {
  top: 16px;
  right: 16px;
  color: #0e0e0e;
}
:global .registry-product-purchased-modal .modal-close:hover {
  color: #4a4a4a;
}
