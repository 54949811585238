.letterpressTag {
  background-color: #f5f9fc !important;
}
.letterpressTag > div {
  color: #55a1bf;
}
.letterpressTag.letterpressEffect > div {
  background-color: #0075ae;
  color: transparent;
  font-weight: 600;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: -0.5px 0.5px 1px rgba(255, 255, 255, 0.7);
}
