.registryItemModal {
  background-color: #ffffff;
}
@media (min-width: 1200px) {
  :global .registry-item-modal {
    width: 1024px !important;
  }
}
@media (max-width: 767px) {
  :global .registry-item-modal {
    max-height: 100dvh;
    margin: 0 !important;
  }
}
:global .registry-item-modal .modal-content {
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: none;
}
@media (max-width: 767px) {
  :global .registry-item-modal .modal-content {
    min-height: 100dvh;
    border-radius: 0;
  }
}
:global .registry-item-modal .modal-content .modal-close {
  top: 16px;
  right: 16px;
  color: #0e0e0e;
}
:global .registry-item-modal .modal-content .modal-close:hover {
  color: #4a4a4a;
}
