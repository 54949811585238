.zola-ui-imported {
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}
@media (min-width: 768px) {
  .zola-ui-imported {
    height: 44px;
  }
}
.zola-ui-imported {
  background-color: #0e0e0e;
  color: #ffffff;
}
.zola-ui-imported:hover {
  color: #ffffff;
  background-color: #4a4a4a;
}
.zola-ui-imported:active {
  background-color: #0e0e0e;
}
.zola-ui-imported:disabled {
  background-color: #b7b7b7;
}
.zola-ui-imported {
  text-transform: none;
  letter-spacing: 0;
}
.zola-ui-imported #csv-upload {
  display: none;
}
