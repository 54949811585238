.babyErrorPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding: 40px;
}
@media (max-width: 1023px) {
  .babyErrorPage {
    padding: 24px;
  }
}
