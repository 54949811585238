.review-custom-messages-modal {
  padding: 40px;
}
@media (max-width: 767px) {
  .review-custom-messages-modal {
    padding: 40px 20px 20px;
  }
}
.review-custom-messages-modal .modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #e5eef7;
  color: #336081;
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  margin-bottom: 16px;
  border-radius: 8px;
}
.review-custom-messages-modal .modal-header .header__title .zolaicon {
  margin-right: 0.5em;
}
.review-custom-messages-modal .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  gap: 20px;
}
@media (max-width: 991px) {
  .review-custom-messages-modal .modal-body {
    justify-content: center;
  }
}
.review-custom-messages-modal .modal-body .custom-messages-preview-container {
  width: 100%;
  height: auto;
  background-color: #FFF;
  position: relative;
  box-shadow: 0px 2px 10px 0px #D9D9D9;
  align-items: center;
  display: flex;
  justify-content: center;
}
.review-custom-messages-modal .modal-body .custom-messages-preview-container > p {
  margin: 0;
  overflow-wrap: break-word;
  white-space: pre-line;
}
