.widthOverride {
  width: 50%;
}
.widthOverride :global(.dropdown-v2__container) {
  min-width: 0;
}
.textOverride {
  text-align: right;
}
.spacerOverride {
  margin: 6px;
}
.formButtonsOverride {
  justify-content: flex-end;
}
.nextOverride {
  flex: none;
}
@media (min-width: 768px) {
  .modal-sm {
    width: 568px;
  }
}
.hide {
  display: none !important;
}
.imageContainer {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}
.container {
  padding: 10px;
}
.container :global(.label__text) {
  text-transform: none;
}
