.detailBox {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #767676;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 200;
  letter-spacing: normal;
  line-height: 1.5;
  padding: 12px;
  width: 220px;
}
@media (max-width: 767px) {
  .detailBox {
    width: 100%;
  }
}
.detailBoxTitle {
  color: #505050;
  font-weight: 400;
  line-height: 1;
  margin-top: 8px;
}
.detailBoxDescription {
  margin-top: 8px;
}
.imageBlock {
  height: auto;
  max-width: 100%;
}
