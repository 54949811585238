/**
 * @prettier
 */
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
:local .checkbox :global(label) {
  text-transform: none;
}
:local .field {
  padding-top: 12px;
  width: 100%;
}
:local .field :global(label.dropdown-v2__label) {
  margin-right: 5px;
}
:local .field.small {
  flex: 0.4 0.4 40%;
}
:local .invalidInput :global(.render-field input.input-field) {
  border-color: #c7133e;
}
.header {
  font-size: 24px;
  font-weight: 600;
}
.text {
  margin: 12px 0;
  max-width: 100%;
}
:local .dropdown {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  padding-top: 12px;
  position: relative;
  top: 2px;
}
:local .dropdown :global(.facet-dropdown) {
  height: 100%;
  margin: 0;
}
:local .dropdown :global(.facet-dropdown) :global(.dropdown__select) {
  height: 100%;
  margin: 0;
}
:local .dropdown :global(.facet-dropdown) :global(.dropdown__options) {
  max-height: 200px;
  overflow-y: scroll;
}
:local .dropdown label {
  font-weight: 700;
}
:local .dropdown label .requiredLabel {
  color: #c7133e;
}
.formButtonBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: 40px;
  width: 99%;
}
.backButton {
  align-self: left;
}
.nextButton {
  flex: 1;
}
.spacer {
  margin: 12px;
}
@media (max-width: 767px) {
  .spacer.hideOnMobile {
    display: none;
  }
}
.inputGroup {
  display: flex;
  flex: 1;
  flex-direction: row;
}
@media (max-width: 767px) {
  .inputGroup.columnOnMobile {
    flex-direction: column;
  }
}
.row {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
}
.col {
  flex: 1 1 100%;
  flex-direction: column;
  padding: 20px;
}
.box {
  flex: 1 1 100%;
  padding: 20px;
}
@media (max-width: 767px) {
  .box {
    padding: 0;
  }
}
.previewBox {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 20px;
}
@media (max-width: 767px) {
  .previewBox {
    display: none;
  }
}
.defensiveDropdownFix :global(.dropdown__select) {
  margin-left: 0 !important;
}
