.modalHeader {
  padding: 0 40px;
}
.modalTitle {
  text-align: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
}
.modalBody {
  padding: 30px 40px;
}
.suggestionItem {
  border-bottom: 1px solid #d9d9d9;
}
.suggestionBtn {
  align-items: center;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
}
.suggestionBtn:hover {
  background: #e8f4f6;
}
.suggestionSelect {
  white-space: nowrap;
}
