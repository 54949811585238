@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.controlsOverlayMobile {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(249, 249, 249, 0.2);
  border: 4px solid #55a1bf;
}
.controlsOverlayTouch,
.controlsOverlayDesktop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 16px;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
}
.controlsOverlayDesktop {
  display: flex;
}
.controlsOverlayDesktop:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.controlsOverlayDesktop:hover .controlButtonDesktop {
  display: flex;
}
.controlsOverlayTouch {
  display: none;
}
.controlsOverlayTouch.active {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
}
.controlsOverlayTouch.active .controlButtonDesktop {
  display: flex;
}
.controlButtonDesktop {
  display: none;
  background: none;
  border: none;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  outline: none;
  text-align: center;
  text-shadow: 0 1px black;
}
.controlButtonDesktop.draggable {
  cursor: grab;
}
