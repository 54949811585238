.title {
  margin-bottom: 24px !important;
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 0 !important;
  }
}
.dek {
  color: #505050 !important;
  margin-top: 16px !important;
  padding: 0 20px;
  font-size: 24px !important;
  line-height: 36px !important;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  .dek {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}
.cityInput {
  width: 528px;
  margin: 40px auto 0;
  max-width: 100vw;
}
@media (max-width: 767px) {
  .cityInput {
    padding: 0 20px;
    margin-top: 24px;
  }
}
.questionContainer :global .question__container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.autoSuggestionContainerOther {
  border-top: 1px solid #d9d9d9;
}
.extraPadding {
  padding-bottom: 200px;
}
.autoSuggest {
  position: relative;
}
.autoSuggest :global .react-autosuggest__container {
  position: relative;
}
.autoSuggest :global .react-autosuggest__suggestions-list {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.autoSuggest :global .react-autosuggest__suggestions-list::-webkit-scrollbar {
  display: none;
}
.autoSuggest :global .react-autosuggest__suggestions-list::-webkit-scrollbar {
  display: none;
}
.autoSuggest :global .react-autosuggest__suggestions-container {
  position: absolute;
  right: 0;
  left: 0;
}
.autoSuggest :global .react-autosuggest__suggestions-container--open {
  overflow-y: scroll;
  z-index: 10;
  max-height: calc(235px);
  border: none;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  color: #0e0e0e;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.autoSuggest :global .react-autosuggest__suggestions-container--open::-webkit-scrollbar {
  display: none;
}
.autoSuggest :global .react-autosuggest__suggestions-container--open::-webkit-scrollbar {
  display: none;
}
.autoSuggest :global .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion {
  height: 47px;
  line-height: 47px;
}
.autoSuggest :global .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion--highlighted {
  background-color: #f3f3f3;
}
.autoSuggest :global .react-autosuggest__suggestions-container--open .auto-suggestion-container {
  padding: 0 12px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: none;
  font-size: 16px;
  font-weight: 400;
}
.autoSuggest :global .react-autosuggest__input {
  position: relative;
}
@media (max-width: 767px) {
  .autoSuggest :global .react-autosuggest__input {
    font-size: 24px !important;
  }
}
.autoSuggest :global .react-autosuggest__input::placeholder {
  cursor: default;
  font-weight: 400;
}
@media (max-width: 767px) {
  .autoSuggest :global .react-autosuggest__input::placeholder {
    font-size: 24px !important;
  }
}
.autoSuggest :global .autosuggest__suggestion--first {
  border-top: none !important;
}
.removeIcon {
  position: absolute;
  right: 10px;
  bottom: 0;
  height: 100% !important;
  cursor: pointer;
}
