@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.checklist__deleteTask--container {
  padding: 15px 15px 25px;
  text-align: center;
  max-width: 465px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .checklist__deleteTask--container {
    padding: 30px 0;
  }
}
.checklist__deleteTask--task {
  margin: 20px 0;
  padding: 20px 15px 20px 60px;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #505050;
  position: relative;
  min-width: 180px;
  text-align: left;
}
.checklist__deleteTask--task:before {
  content: '';
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  top: 50%;
  height: 24px;
  width: 24px;
  left: 15px;
  transform: translateY(-50%);
}
@media (min-width: 992px) {
  .checklist__deleteTask--task {
    font-size: 16px;
    max-width: 300px;
    margin: 20px auto;
  }
}
.checklist__deleteTask--taskTitle {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  max-height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.checklist__deleteTask--warningCopy {
  font-size: 14px;
}
@media (min-width: 992px) {
  .checklist__deleteTask--warningCopy {
    font-size: 12px;
  }
}
.checklist__deleteTask--footer {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .checklist__deleteTask--footer {
    max-width: 240px;
    margin: 25px auto 0;
  }
}
.checklist__deleteTask--cancel {
  margin-top: 25px;
}
