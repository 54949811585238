.suiteTileWrapper {
  display: flex;
  width: 50%;
}
@media (min-width: 768px) {
  .suiteTileWrapper {
    width: 25%;
    max-width: 290px;
  }
}
.suiteTile {
  padding: 8px;
  border-radius: 8px;
  margin: 12px 2px;
}
.suiteTileActive {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.suiteTileImg {
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}
.suiteTileName {
  font-size: 16px;
  font-weight: 600;
  margin: 16px 2px 8px;
}
.image {
  position: relative;
}
