.modal {
  padding: 40px;
}
.title {
  color: #21201f;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: 32px 0 12px;
}
.btns {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
.btn {
  margin-right: 12px;
}
