.card-modal-content .img-responsive {
  width: 100%;
}
.modal-body {
  padding-left: 30px;
}
.modal-body h3 {
  margin-bottom: 20px;
}
.modal-body .paper-type-section {
  margin-bottom: 20px;
}
.modal-body .section-header {
  font-size: 16px;
  font-weight: 700;
}
.modal-body .paper-type-section-row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.modal-body .paper-type-img-column {
  flex: 0 0 auto;
  margin: 0 40px 0 0;
  max-width: 270px;
  width: auto;
}
.modal-body .img-wrapper {
  height: auto;
  overflow: hidden;
  width: 100%;
}
