@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.sectionMessageContainer {
  padding: 0 20px;
}
@media (max-width: 767px) {
  .sectionMessageContainer {
    padding: 0 20px;
    width: 100%;
  }
}
.sectionMessage {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .sectionMessage {
    margin-bottom: 0;
  }
}
.sectionMessage h2 {
  font-weight: 400;
}
.title {
  display: flex;
}
.alignIcon {
  border: solid;
  border-color: #0075ae;
  border-width: 1px;
  color: #0075ae;
  font-size: 0.8rem;
  padding: 6px 8px 6px 3px;
}
.editLinks {
  color: #21201f;
  padding-bottom: 12px;
}
.checklistItemHeader {
  font-weight: 600;
}
.checklistBullet {
  background-image: url(../../../../assets/images/icons/check.svg);
  background-size: 100% 100%;
  margin-right: 8px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  position: relative;
  top: 2px;
}
.checklistItem {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 12px 0;
}
.checklist {
  padding: 0;
}
.checklistContainer {
  color: #21201f;
  display: flex;
}
.box {
  display: flex;
  flex-direction: column;
  margin: 0 48px;
  padding: 24px 0;
}
