@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.cancel-order-modal .modal-header h3 {
  font-family: 'circular', serif;
  margin: 0;
}
.cancel-order-modal .modal-body:before,
.cancel-order-modal .modal-body:after {
  display: table;
  content: " ";
}
.cancel-order-modal .modal-body:after {
  clear: both;
}
.cancel-order-modal .modal-body:before,
.cancel-order-modal .modal-body:after {
  display: table;
  content: " ";
}
.cancel-order-modal .modal-body:after {
  clear: both;
}
.cancel-order-modal .modal-body .action-buttons {
  float: right;
}
.cancel-order-modal .modal-body .action-buttons button {
  margin-left: 10px;
}
@media (max-width: 768px) {
  .cancel-order-modal .modal-body .action-buttons button {
    margin: 5px 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}
