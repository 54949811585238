.filterMenuContent {
  max-height: 315px;
  overflow-y: scroll;
  padding: 20px 20px 0 20px;
}
.filterMenuContent::after {
  content: '';
  display: block;
  height: 20px;
}
.filterMenuContent::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.filterMenuContent::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
@media (max-width: 768px) {
  .filterMenuContent {
    max-height: none;
    overflow-y: auto;
  }
}
.clearBtn {
  border-color: transparent !important;
  box-shadow: none !important;
  color: #505050 !important;
}
.clearBtn:hover {
  background-color: #EEE !important;
}
.clearBtnDisabled {
  background-color: transparent !important;
  color: #D9D9D9 !important;
}
.clearBtnDisabled:hover {
  background-color: transparent !important;
}
