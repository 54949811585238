/**
 * @prettier
 */
.header {
  transition: all 500ms;
  overflow: hidden;
  height: 25px;
  opacity: 1;
}
@media screen and (max-width: 991px) {
  .header {
    display: none;
  }
}
.title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  line-height: 1.58;
}
.productDetailPriceSection {
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: all 500ms;
}
@media screen and (max-width: 991px) {
  .productDetailPriceSection {
    justify-content: center;
  }
}
.qtySelect {
  width: 112px;
  margin-right: 10px;
}
@media screen and (max-width: 991px) {
  .qtySelect {
    display: none;
  }
}
.mobileSelectContainer {
  display: none;
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-width: 95px;
  margin-right: 16px;
}
@media screen and (max-width: 991px) {
  .mobileSelectContainer {
    display: block;
  }
}
.mobileSelectContainer::after {
  font-family: 'zolaicons';
  content: '\e836';
  text-shadow: 0 1px 0 #0075ae;
  background-color: #fff;
  color: #0075ae;
  position: absolute;
  right: 10px;
  top: 11px;
  transform: rotate(-90deg);
  z-index: -1;
}
.mobileSelectContainer::after::before {
  font-weight: 600;
}
.mobileSelect {
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-family: 'circular', serif;
  color: #21201f;
  line-height: 1.3;
  padding: 11px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 8px 0 0;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}
.mobileSelect::-ms-expand {
  display: none;
}
.mobileSelect:focus {
  outline: none;
}
.mobileSelect option {
  font-weight: normal;
}
.priceTotal {
  color: #505050;
  height: 24px;
  font-family: 'circular', serif;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  margin-left: 16px;
  min-width: 62px;
}
@media screen and (max-width: 991px) {
  .priceTotal {
    font-size: 16px;
    font-weight: 400;
    text-align: right;
    color: #505050;
    height: inherit;
  }
}
@media screen and (min-width: 992px) {
  .isShowingPostAuthNav3 {
    margin-left: 74px;
  }
}
