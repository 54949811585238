:global(.downloadDSTDImageModal) {
  max-width: calc(100% - 20px);
  width: 568px !important;
}
.downloadImagedWrapper {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 40px;
}
.retryModal {
  align-items: flex-end;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
