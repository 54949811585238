#uninvite-guest-confirmation-modal .modal-header {
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
}
#uninvite-guest-confirmation-modal .modal-header h3 {
  margin: 0;
}
#uninvite-guest-confirmation-modal .modal-body {
  margin: 0 auto;
  text-align: center;
  padding: 40px 80px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 20px;
}
#uninvite-guest-confirmation-modal .modal-body p {
  margin-bottom: 20px;
}
#uninvite-guest-confirmation-modal .modal-actions .btn:first-of-type {
  margin-right: 5px;
}
#uninvite-guest-confirmation-modal .modal-actions .btn:last-of-type {
  margin-left: 5px;
}
