.cardWrapper {
  float: none !important;
  margin: 0 0 40px 0;
}
.card {
  display: block;
  font-size: 16px;
  font-weight: 600;
  max-width: 400px;
  padding: 0 10px;
  position: relative;
}
.card:hover .cardSubtitle {
  text-decoration: none;
}
.cardImage {
  aspect-ratio: 1;
  object-fit: contain;
  /* Max height should match the asset width from ../Card.tsx */
  max-height: 380px;
  max-width: 100%;
}
.favorite {
  position: absolute;
  right: 0;
  top: 0;
}
@media screen and (max-width: 991px) {
  .favorite {
    right: 0;
  }
}
.suiteDetails {
  margin-top: 8px;
  padding-left: 12px;
}
.suiteDetails .cardTitle {
  display: flex;
  font-size: 16px;
  line-height: 20px;
}
@media screen and (max-width: 991px) {
  .suiteDetails .cardTitle {
    flex-direction: column;
  }
}
.suiteDetails .cardSubtitle {
  color: #505050;
  font-weight: 400;
  margin-top: 8px;
}
.tag {
  margin-left: 8px;
}
@media screen and (max-width: 991px) {
  .tag {
    margin-left: 0;
    margin-top: 8px;
  }
}
