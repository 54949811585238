.apManagementPage {
  background-color: #ebf4f9;
  background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-web-management-page.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  font-size: 16px !important;
  line-height: 16px;
  margin-top: 30px;
  padding: 20px 28px 20px 16px !important;
  text-transform: none !important;
}
@media (max-width: 991px) {
  .apManagementPage {
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0;
    margin-top: 0;
    width: 100%;
  }
}
