.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
}
.row {
  display: flex;
  gap: 12px;
}
@media (max-width: 767px) {
  .row {
    flex-direction: column;
  }
}
.input {
  flex-grow: 1;
}
.addressInput {
  width: 109px;
}
@media (max-width: 767px) {
  .addressInput {
    width: auto;
  }
}
.postalCodeInput {
  width: 124px;
}
@media (max-width: 767px) {
  .postalCodeInput {
    width: auto;
  }
}
