.optionListWrapper {
  position: relative;
}
.optionList {
  display: block;
  position: absolute;
  z-index: 101;
  left: -234px;
  transform: translateY(-50%);
  -moz-transform: translateY(-96px);
  top: 46px;
}
.optionListMobileDetails {
  display: none;
}
@media (max-width: 1199px) {
  .optionListWrapper > .optionList {
    display: none;
  }
  .optionListWrapper > .optionList.showOnSmallDesktop {
    display: block;
  }
}
@media (max-width: 991px) {
  .optionListWrapper > .optionList.showOnSmallDesktop {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .optionListMobileDetails {
    margin-top: 12px;
    display: block;
  }
}
