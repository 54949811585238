.container {
  width: auto;
}
.containerV3 {
  padding: 72px 48px 48px;
}
.modalBody {
  padding: 20px 30px;
}
.title {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.titleV3 {
  margin-bottom: 12px;
}
.text {
  color: #0E0E0E;
  font-size: 16px;
  line-height: 24px;
}
.buttonWrapper {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 32px;
}
@media (max-width: 767px) {
  .buttonWrapper {
    flex-direction: column-reverse;
  }
}
.svg {
  height: 100px;
  margin-bottom: 32px;
}
