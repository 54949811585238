@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.decline-modal-container {
  background-color: #ffffff;
  border: solid 1px #d9d9d9;
  border-radius: 1px;
  height: 230px;
}
.decline-modal-container .body {
  margin-left: 40px;
  margin-top: 40px;
}
.decline-modal-container .body h1 {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 30px;
}
.decline-modal-container .body p {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  line-height: 1.38;
}
.decline-modal-container .cta-section {
  margin-right: 45px;
  margin-top: 20px;
  text-align: right;
}
.decline-modal-container .cta-section .ctas .danger-btn {
  margin-left: 10px;
}
.decline-modal-container .cta-section .ctas .danger-btn:hover {
  background-color: #c7133e;
  border-color: #c7133e;
}
.decline-modal-container .bottom-copy {
  margin-top: 30px;
  text-align: center;
}
