@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.event-modal.-v2 {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  .event-modal.-v2 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  .event-modal.-v2 {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.event-modal.-v2 .modal__header,
.event-modal.-v2 .event-form__other-details {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
@media (min-width: 768px) {
  .event-modal.-v2 .modal__header,
  .event-modal.-v2 .event-form__other-details {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  .event-modal.-v2 .modal__header,
  .event-modal.-v2 .event-form__other-details {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 768px) {
  .event-modal.-v2 .modal__header,
  .event-modal.-v2 .event-form__other-details {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (min-width: 992px) {
  .event-modal.-v2 .modal__header,
  .event-modal.-v2 .event-form__other-details {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: -40px;
    margin-right: -40px;
  }
}
.event-modal.-v2 .modal__header {
  border-top: none;
}
.event-modal.-v2 .event-form__other-details {
  padding-top: 24px;
  padding-bottom: 24px;
}
.event-modal.-v2 .modal__header,
.event-modal.-v2 .event-form__submit-section,
.event-modal.-v2 .event-form__basic-details,
.event-modal.-v2 .event-form__suggestions {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 992px) {
  .event-modal.-v2 .modal__header,
  .event-modal.-v2 .event-form__submit-section,
  .event-modal.-v2 .event-form__basic-details,
  .event-modal.-v2 .event-form__suggestions {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.event-modal.-v2 .event-form__basic-details {
  padding-top: 8px;
}
@media (min-width: 992px) {
  .event-modal.-v2 .event-form__basic-details {
    padding-top: 14px;
  }
}
.event-modal.-v2 .event-form__submit-section {
  text-align: right;
}
.event-modal.-v2 .modal__title,
.event-modal.-v2 .accordion__title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.event-modal.-v2 .accordion__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.event-modal.-v2 .accordion__header .zolaicon {
  font-size: 24px;
  color: #0e0e0e;
  margin-left: auto;
}
.event-modal.-v2 .event-form .render-field,
.event-modal.-v2 .event-form .form-group {
  margin-top: 21px;
  margin-bottom: 0;
}
.event-modal.-v2 .event-form .render-field label,
.event-modal.-v2 .event-form .form-group label,
.event-modal.-v2 .event-form .form__label,
.event-modal.-v2 .event-form .zola-ui__toggle-label {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: normal;
}
.event-modal.-v2 .event-form .form__label {
  font-size: 20px;
}
.event-modal.-v2 .event-form .zola-ui.render-field input {
  font-size: 14px;
}
.event-modal.-v2 .event-form .website-settings .zola-ui__toggle-label {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  color: #21201f;
  margin-bottom: 0;
}
.event-modal.-v2 .event-form .website-settings .website-settings__options {
  margin-top: 9px;
}
.event-modal.-v2 .event-form .website-settings .website-settings__toggle {
  display: block;
  margin-bottom: 0;
}
.event-modal.-v2 .event-form .website-settings .website-settings__toggle:not(:first-child) {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .event-modal.-v2 .event-form .website-settings .website-settings__toggle {
    margin-top: 0;
    display: inline-block;
  }
  .event-modal.-v2 .event-form .website-settings .website-settings__toggle:not(:first-child) {
    margin-left: 24px;
  }
}
.event-modal.-v2 .event-form .action-link {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #0e0e0e;
  cursor: pointer;
}
@media (min-width: 768px) {
  .event-modal.-v2 .event-form .action-link {
    margin-left: 10px;
    display: inline-block;
  }
}
.event-modal.-v2 .event-form .action-link .zolaicon {
  font-size: 10px;
}
.event-modal.-v2 .event-form .action-link:hover {
  text-decoration: underline;
}
.event-modal.-v2 .event-form .zola-ui .input-field__container {
  margin-top: 0;
}
.event-modal.-v2 .event-form .danger-asterisk {
  font-size: 16px;
  line-height: normal;
}
.event-modal.-v2 .event-form .form-group.venue-name {
  position: relative;
  margin-top: 32px;
}
.event-modal.-v2 .event-form .form-group.venue-name .pull-right {
  position: absolute;
  right: 0;
}
.event-modal.-v2 .event-form .form-group.venue-name .form-group {
  margin-top: 0;
}
.event-modal.-v2 .event-form .form-group.venue-name .form-group.has-error {
  margin-top: 4px;
}
.event-modal.-v2 .event-form .add-rsvp-question,
.event-modal.-v2 .event-form .add-meal-option {
  font-size: 16px;
  font-weight: 600;
}
.event-modal.-v2 .event-form .event-submit-button:before,
.event-modal.-v2 .event-form .event-submit-button:after {
  display: table;
  content: " ";
}
.event-modal.-v2 .event-form .event-submit-button:after {
  clear: both;
}
.event-modal.-v2 .event-form .event-submit-button:before,
.event-modal.-v2 .event-form .event-submit-button:after {
  display: table;
  content: " ";
}
.event-modal.-v2 .event-form .event-submit-button:after {
  clear: both;
}
.event-modal.-v2 .event-form .event-submit-button .btn {
  float: right;
}
.event-modal.-v2 .sortable-rsvp-question-row,
.event-modal.-v2 .sortable-meal-option-row {
  z-index: 1100;
  margin-top: 12px;
}
.event-modal.-v2 .sortable-rsvp-question-row:last-child,
.event-modal.-v2 .sortable-meal-option-row:last-child {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .event-modal.-v2 .sortable-rsvp-question-row .meal-option-row,
  .event-modal.-v2 .sortable-meal-option-row .meal-option-row {
    max-width: 240px;
  }
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-row,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-row,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-row,
.event-modal.-v2 .sortable-meal-option-row .meal-option-row {
  position: relative;
  padding-left: 26px;
  padding-right: 26px;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-name,
.event-modal.-v2 .sortable-meal-option-row .meal-option-name {
  margin-bottom: 0;
  margin-top: 0;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question.rsvp-question-display,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question.rsvp-question-display,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-name.rsvp-question-display,
.event-modal.-v2 .sortable-meal-option-row .meal-option-name.rsvp-question-display,
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question.meal-option-name-display,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question.meal-option-name-display,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-name.meal-option-name-display,
.event-modal.-v2 .sortable-meal-option-row .meal-option-name.meal-option-name-display {
  padding: 8px;
  height: 38px;
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question .form-control,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question .form-control,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-name .form-control,
.event-modal.-v2 .sortable-meal-option-row .meal-option-name .form-control {
  padding: 8px;
  border-color: #01b4c0;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-count,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-count,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-count,
.event-modal.-v2 .sortable-meal-option-row .meal-option-count {
  color: #7f7f7f;
  margin-left: 12px;
  margin-top: -8px;
  font-size: 12px;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-count.rsvp-question-count-editing,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-count.rsvp-question-count-editing,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-count.rsvp-question-count-editing,
.event-modal.-v2 .sortable-meal-option-row .meal-option-count.rsvp-question-count-editing,
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-count.meal-option-count-editing,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-count.meal-option-count-editing,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-count.meal-option-count-editing,
.event-modal.-v2 .sortable-meal-option-row .meal-option-count.meal-option-count-editing {
  margin-top: 2px;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-delete,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-delete,
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-edit,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-edit,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-delete,
.event-modal.-v2 .sortable-meal-option-row .meal-option-delete,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-edit,
.event-modal.-v2 .sortable-meal-option-row .meal-option-edit,
.event-modal.-v2 .sortable-rsvp-question-row .sortable-handle,
.event-modal.-v2 .sortable-meal-option-row .sortable-handle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #a8a8aa;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-delete:hover,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-delete:hover,
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-edit:hover,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-edit:hover,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-delete:hover,
.event-modal.-v2 .sortable-meal-option-row .meal-option-delete:hover,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-edit:hover,
.event-modal.-v2 .sortable-meal-option-row .meal-option-edit:hover,
.event-modal.-v2 .sortable-rsvp-question-row .sortable-handle:hover,
.event-modal.-v2 .sortable-meal-option-row .sortable-handle:hover {
  color: #0e0e0e;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-delete .zolaicon,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-delete .zolaicon,
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-edit .zolaicon,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-edit .zolaicon,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-delete .zolaicon,
.event-modal.-v2 .sortable-meal-option-row .meal-option-delete .zolaicon,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-edit .zolaicon,
.event-modal.-v2 .sortable-meal-option-row .meal-option-edit .zolaicon,
.event-modal.-v2 .sortable-rsvp-question-row .sortable-handle .zolaicon,
.event-modal.-v2 .sortable-meal-option-row .sortable-handle .zolaicon {
  font-size: 16px;
}
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-delete .zolaicon-edit,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-delete .zolaicon-edit,
.event-modal.-v2 .sortable-rsvp-question-row .rsvp-question-edit .zolaicon-edit,
.event-modal.-v2 .sortable-meal-option-row .rsvp-question-edit .zolaicon-edit,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-delete .zolaicon-edit,
.event-modal.-v2 .sortable-meal-option-row .meal-option-delete .zolaicon-edit,
.event-modal.-v2 .sortable-rsvp-question-row .meal-option-edit .zolaicon-edit,
.event-modal.-v2 .sortable-meal-option-row .meal-option-edit .zolaicon-edit,
.event-modal.-v2 .sortable-rsvp-question-row .sortable-handle .zolaicon-edit,
.event-modal.-v2 .sortable-meal-option-row .sortable-handle .zolaicon-edit {
  font-size: 14px;
}
.event-modal.-v2 .sortable-rsvp-question-row .sortable-handle,
.event-modal.-v2 .sortable-meal-option-row .sortable-handle {
  left: 0;
  right: auto;
  width: auto;
  height: auto;
  margin-right: 0;
}
.event-modal.-v2 .accordion .accordion__body {
  display: none;
}
.event-modal.-v2 .accordion.-opened .accordion__body {
  display: block;
}
.event-modal.-v2 .event-form__suggestions .modal__header {
  padding-top: 40px;
}
.event-modal.-v2 .event-form__suggestions .modal__body {
  padding: 30px 50px;
  padding-top: 0px;
}
.event-modal.-v2 .event-form__suggestions .back-button {
  position: absolute;
  top: 10px;
  left: 40px;
}
.event-modal.-v2 .event-form__suggestions .suggestion {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: solid 1px #d9d9d9;
}
.event-modal.-v2 .event-form__suggestions .suggestion:hover {
  background: #e8f4f6;
}
.event-modal.-v2 .event-form__suggestions .suggestion__text {
  padding-right: 10px;
}
.event-modal.-v2 .event-form__suggestions .suggestion__select {
  letter-spacing: normal;
  margin-left: auto;
  text-transform: none;
  white-space: nowrap;
}
