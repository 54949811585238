.guest__upload-matching > h1 {
  margin: 32px auto 0;
  text-align: center;
}
.guest__upload-matching > p {
  color: #505050;
  font-size: 20px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: center;
}
.guest__upload-matching > p > .button-upload {
  margin-left: 5px;
}
.mapping-container {
  width: 720px;
  margin: 0 auto;
}
.mapping-container .title {
  font-size: 12px;
  color: #767676;
}
.column-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 80px;
}
.zola-list {
  padding-left: 10px;
  margin-left: -10px;
  overflow-y: auto;
  width: 250px;
}
.zola-list__item {
  height: 44px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  cursor: grab;
  width: 235px;
  margin-bottom: 8px;
  padding-left: 8px;
  display: flex;
  align-items: center;
}
.zola-list__item:before {
  content: '\283F';
  color: #0075ae;
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
  margin-right: 12px;
}
.zola-list__item > .item__text {
  display: inline-block;
  font-size: 16px;
  color: #505050;
  line-height: 1;
  height: auto;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zola-list__item.drag-active {
  cursor: grabbing;
}
.zola-list__item.placeholder {
  box-shadow: none;
  color: #d9d9d9;
  cursor: default;
  opacity: 0.5;
}
.zola-list__item.placeholder:before {
  opacity: 0.5;
}
.user-list {
  width: 450px;
}
.user-list > .button-container {
  text-align: right;
}
.user-list > .button-container > .primary-button {
  margin-left: 24px;
}
.user-list__list {
  width: 450px;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  background-color: #fcfcfc;
  padding: 20px;
  margin-bottom: 40px;
}
.user-list__item {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.user-list__item > .item__text {
  color: #505050;
  font-size: 16px;
  font-weight: 400;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-list__item > .item__target {
  border-radius: 4px;
  border: dashed 1px #c0c0c0;
  background-color: white;
  position: relative;
  height: 44px;
  width: 235px;
}
.user-list__item > .item__target.hover {
  border-color: #0075ae;
}
.user-list__item > .item__target:before {
  content: '';
  position: absolute;
  top: 15px;
  left: -30px;
  border-top: 4px solid transparent;
  border-left: 4px solid #767676;
  border-bottom: 4px solid transparent;
}
.user-list__item > .item__target:after {
  content: '';
  position: absolute;
  top: 15px;
  left: -31px;
  border-top: 4px solid transparent;
  border-left: 4px solid white;
  border-bottom: 4px solid transparent;
}
.zola-list__dropdown.facet-dropdown {
  margin-top: 4px;
  width: 235px;
}
.zola-list__dropdown.facet-dropdown.pull-left > .dropdown__select {
  margin-left: 0;
}
.zola-list__dropdown.facet-dropdown > .dropdown__select {
  border-radius: 4px;
}
.zola-list__dropdown.facet-dropdown > .dropdown__select:hover {
  border-color: #d9d9d9;
}
.zola-list__dropdown.facet-dropdown > .dropdown__select .dropdown__icon {
  color: #0075ae;
  font-size: 20p;
}
.zola-list__dropdown.facet-dropdown > .dropdown__select--toggled {
  border-color: #d9d9d9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #767676;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
}
.zola-list__dropdown.facet-dropdown > .dropdown__options {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  margin-top: 0;
  width: 235px;
}
.zola-list__dropdown.facet-dropdown .dropdown__option.link-option {
  color: #505050;
  padding-top: 10px;
  padding-bottom: 10px;
}
.zola-list__dropdown.facet-dropdown .dropdown__option {
  border: 1px solid #d9d9d9;
  border-top: none;
}
/* TODO: set up robust notification in zola-ui that handles:
//    • a panel layout for lots of text
//    • header + panel design
//    • close buttons
*/
.zui-toasts.mapping {
  color: white;
  font-weight: 600;
}
.zui-toasts.mapping p {
  color: white;
}
.zui-toasts.mapping .mapping-msg__errors {
  max-height: 215px;
  overflow: scroll;
  width: 100%;
}
.zui-toasts.mapping .mapping-msg__errors > p {
  margin-bottom: 1em;
}
@media (max-width: 992px) {
  .noguest-value-props > .container > .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .noguest-value-props > .container > .row > .value-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    width: 80%;
  }
  .noguest-value-props > .container > .row > .value-item > .zolaicon {
    margin-bottom: 0;
    height: 50px;
    min-width: 60px;
  }
}
