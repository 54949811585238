@media (max-width: 991px) {
  .listingDetail {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    min-height: 300px;
  }
}
.listingImg {
  max-width: 100%;
}
.adTileImg {
  max-height: 480px;
  max-width: 100%;
  width: auto;
}
.adTileLink {
  text-align: center;
}
.digital {
  align-items: center;
  background-color: #F7D7D2;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 376px;
  overflow: hidden;
  padding: 30px 40px 64px 40px;
  position: relative;
}
@media (max-width: 991px) {
  .digital {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    min-height: 274px;
    padding: 0;
    width: 100%;
  }
}
.content {
  flex-grow: 1;
  max-width: 50%;
  z-index: 2;
}
@media (max-width: 991px) {
  .content {
    flex: 0 0 auto;
    max-width: none;
    padding: 0 12px 12px 12px;
    width: 100%;
  }
}
.digitalImg {
  bottom: 0;
  max-width: 50%;
  position: absolute;
  right: 0;
  z-index: 1;
}
@media (max-width: 991px) {
  .digitalImg {
    flex: 1 1 auto;
    max-width: 100%;
    padding: 12px;
    position: relative;
  }
}
.digitalImg img {
  display: block;
}
.digitalTitle {
  color: #21201F;
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  margin-bottom: 8px;
}
@media (max-width: 1199px) {
  .digitalTitle {
    width: 300px;
  }
}
@media (max-width: 991px) {
  .digitalTitle {
    font-family: 'circular', helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    width: 100%;
  }
}
.digitalContent {
  color: #505050;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .digitalContent {
    display: none;
  }
}
.digitalList {
  list-style: decimal !important;
}
.digitalBtn {
  display: block !important;
}
@media (max-width: 991px) {
  .digitalBtn {
    display: none !important;
  }
}
.digitalLink {
  display: none !important;
}
@media (max-width: 991px) {
  .digitalLink {
    display: block !important;
  }
}
