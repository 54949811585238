.proofButton {
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 100px;
  color: #005759;
  column-gap: 8px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  margin-top: 16px;
  padding: 12px 16px;
  width: fit-content;
}
