.zoomImg {
  will-change: transform;
}
.caption {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 168%);
  bottom: 0;
  color: #ffffff;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  left: 0;
  letter-spacing: normal;
  line-height: normal;
  padding: 40px 16px 16px 16px;
  position: absolute;
  right: 0;
  text-align: left;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
