.previewContainer {
  align-self: flex-start;
  border-radius: 4px;
  transform: translate3d(0, 0, 0);
}
.previewContainer.withFrame {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.previewContainerDefaultSize {
  width: 180px;
  height: 180px;
  padding: 8px;
}
.canvasBoxContainer:not(.trapezoid) {
  height: 100%;
}
.canvasBox {
  position: relative;
  transform-origin: left top;
}
.border {
  background-color: #ffffff;
  box-shadow: 0 6px 10px 3px rgba(0, 0, 0, 0.2);
}
.border.folded {
  position: absolute;
}
.border.rounded {
  border-radius: 8px;
}
.trapezoid {
  transform: perspective(90px) rotateX(1deg);
  position: relative;
  z-index: 2;
}
.foldedBg {
  content: '';
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  width: 165px;
  display: block;
  position: absolute;
  top: 172px;
  height: 10px;
  margin-left: 13px;
}
.previewHand {
  background-image: url('../../../assets/images/digitalSaveTheDate/handDesktop.png');
  background-repeat: no-repeat;
  background-size: contain;
  bottom: -21px;
  height: 140px;
  position: absolute;
  width: 131px;
  z-index: 2;
}
