.modalContent {
  padding: 48px;
}
@media (max-width: 767px) {
  .modalContent {
    padding: 56px 24px 40px;
  }
}
.modalHed {
  margin-bottom: 12px;
}
.modalCheckboxWrapper {
  margin-top: 32px;
}
.modalCtaWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
}
@media (max-width: 767px) {
  .modalCtaWrapper {
    flex-direction: column-reverse;
    gap: 16px;
  }
}
.secondaryCta {
  color: #0e0e0e;
}
