.sampleFlowWrapper {
  padding: 40px;
}
@media screen and (max-width: 991px) {
  .sampleFlowWrapper {
    margin: 0 auto;
    max-width: 100%;
    padding: 40px 0;
    width: 335px;
  }
}
