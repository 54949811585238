.sampleButton {
  bottom: 20px;
  column-gap: 8px;
  display: flex;
  position: absolute;
  right: 20px;
}
@media (max-width: 480px) {
  .sampleButton {
    display: none !important;
  }
}
