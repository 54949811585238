.content-wrapper {
  padding: 60px;
}
.title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: normal;
  margin: 0;
}
.text {
  color: #21201f;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0;
}
.mention {
  color: #767676;
  font-size: 14px;
}
.chart {
  border: solid 2px #d9d9d9;
  margin: 0 0 10px;
  text-align: center;
  width: 100%;
}
.chart__header {
  background: #fafafa;
}
.chart__header__content {
  border: solid 2px #d9d9d9;
  font-size: 14px;
  padding: 15px 10px;
  text-align: center;
  color: #21201f;
}
.chart__body {
  background: #fff;
}
.chart__body__content {
  border: solid 2px #d9d9d9;
  font-size: 14px;
  padding: 15px 10px;
  text-align: center;
  color: #505050;
}
