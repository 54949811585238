.top {
  padding: 20px;
  height: 60px;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.top img {
  height: 20px;
}
.hed {
  padding: 20px;
  text-align: center;
  margin: 0 auto;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
.bgIllustrative {
  background-color: #f6f5f0;
}
.bgJewel8 {
  background-color: #ebf4f9;
}
.bgJewel4 {
  background-color: #f5f9fc;
}
.apricotVariant24 {
  background-color: #fef0eb;
}
.v3container {
  background-color: #f3f3f3;
}
@keyframes fadeIn {
  0% {
    pointer-events: none;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    pointer-events: none;
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
}
.fadeOut {
  animation: fadeOut 0.5s;
  animation-fill-mode: forwards;
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.slideInFromRight {
  animation: slideInFromRight 0.5s;
}
.slideOut {
  transform: translateX(-100%);
  transition: all 0.5s;
}
.inner {
  min-height: 100vh;
}
.absolute {
  position: absolute;
  z-index: 20;
}
