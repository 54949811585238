.link:hover {
  cursor: pointer;
  /* stylelint-disable-next-line declaration-no-important */
  text-decoration: none !important;
}
.image {
  overflow: visible;
  position: relative;
  text-align: center;
}
@media (min-width: 481px) {
  .image {
    margin-bottom: 8px;
  }
}
.image .imageContent {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: contain;
}
.favoriteToggle {
  position: absolute;
  right: 16px;
  top: 16px;
}
@media (max-width: 767px) {
  .favoriteToggle {
    right: 8px;
    top: 8px;
  }
}
.imagePlaceholder::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.productName {
  font-size: 14px;
  line-height: 18px;
  color: #0e0e0e;
  font-weight: 700;
}
@media (min-width: 481px) {
  .productName {
    font-size: 16px;
    line-height: 24px;
  }
}
.productNameSection {
  margin-top: 8px;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
}
.colorPicker {
  margin-top: 8px;
}
.productDescription {
  font-size: 11px;
  line-height: 20px;
  color: #757575;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (min-width: 481px) {
  .productDescription {
    font-size: 14px;
  }
}
.selectedSample {
  border: 2px solid #0075ae;
}
.tag {
  text-transform: none;
}
.multiSampleCheckbox {
  position: absolute;
  top: 20px;
  left: 20px;
}
@media (min-width: 481px) {
  .multiSampleCheckbox {
    top: 24px;
    left: 24px;
  }
}
.listingDetail {
  position: relative;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  text-align: left;
}
@media (max-width: 767px) {
  .listingDetail {
    margin-left: auto;
    margin-right: auto;
  }
}
.listingDetail.disabled {
  opacity: 0.5;
}
.listingDetail:hover .productName {
  color: #4a4a4a;
  text-decoration: underline;
}
.listingDetail .showOnHover {
  display: none;
}
.listingDetail.withControlsOnHover:hover .hideOnHover {
  display: none;
}
.listingDetail.withControlsOnHover:hover .showOnHover {
  display: block;
}
.listingDetail.withControlsOnHover .tileBottom {
  height: 44px;
}
.listingDetail a {
  text-decoration: none;
}
.tilePrice {
  margin-top: 8px;
  width: 100%;
  color: #0e0e0e;
  font-size: 14px;
  line-height: 18px;
}
@media (min-width: 481px) {
  .tilePrice {
    font-size: 16px;
    line-height: 24px;
  }
}
.digitalBubble {
  background-color: #733658;
  border-radius: 50%;
  left: 4px;
  padding: 3px;
  position: absolute;
  top: 4px;
  z-index: 2;
}
.digitalBubbleInner {
  align-items: center;
  background-color: #733658;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 9px;
  font-weight: 400;
  height: 46px;
  justify-content: center;
  line-height: 9px;
  width: 46px;
}
.tileBottom {
  flex: 1 1 auto;
  margin-top: 8px;
  align-items: flex-end;
  display: flex;
  width: 100%;
}
.tileButtonRow {
  width: 100%;
}
.tileButtonRowInnerWrapper {
  display: flex;
  gap: 8px;
}
.tileButtonRowInnerWrapper > * {
  flex: 1;
}
