@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.contextualToolsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
}
.contextualToolsRoot {
  position: absolute;
  z-index: 1;
}
.contextualToolsRoot:empty {
  display: none;
}
.contextualTools {
  position: absolute;
  left: 50%;
  bottom: 100%;
  margin-bottom: 8px;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 100px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.contextualButton {
  color: #757575;
}
.contextualButton:not(.disabled) {
  color: #0e0e0e;
  cursor: pointer;
}
.contextualButton svg {
  display: block;
}
