.tabs :global(.tab) {
  padding-bottom: 5px !important;
  padding-top: 0 !important;
}
.tabCardPreview {
  margin-bottom: 8px;
  width: 56px;
}
.content {
  display: flex;
  flex-direction: column;
}
.previewWrapper {
  align-items: center;
  display: flex;
  height: 330px;
  justify-content: space-around;
  margin-bottom: 12px;
  width: 70%;
  order: 1;
}
@media screen and (max-width: 991px) {
  .previewWrapper {
    height: 320px;
    width: 100%;
  }
}
.colorPicker {
  display: flex;
  justify-content: center;
  order: 3;
}
@media screen and (max-width: 991px) {
  .colorPicker {
    order: 2;
  }
}
.details {
  max-width: 140px;
  position: absolute;
  top: 53%;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  order: 2;
}
@media screen and (max-width: 991px) {
  .details {
    margin-top: 20px;
    max-width: 100%;
    width: 100%;
    text-align: center;
    position: unset;
    order: 3;
  }
}
.text {
  color: #767676;
  font-size: 14px;
}
.preview {
  overflow: hidden;
  position: relative;
  transform-origin: left top;
}
.message {
  color: #15845d;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
}
@media screen and (max-width: 991px) {
  .ctaWrapper {
    display: flex;
    justify-content: space-between;
  }
}
.cta {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #505050;
  display: flex;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 18px;
  margin-left: 8px;
  padding: 0;
}
@media screen and (max-width: 991px) {
  .cta {
    margin-bottom: 0;
    margin-left: 0;
  }
}
.icon {
  margin-right: 4px;
}
.uploadLink {
  margin-bottom: 12px;
}
