.lastUpdated {
  color: #767676;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 23px;
}
@media (max-width: 767px) {
  .container {
    flex-direction: column;
  }
}
.imageContainer {
  align-items: flex-start;
  display: flex;
}
.image {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  max-width: 260px;
}
.customizationsContainer {
  display: flex;
  flex-direction: column;
}
.customization .title {
  color: #21201f;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 600;
  font-weight: 600;
  line-height: 1em;
  margin: 0 0 8px 0;
}
.customization .label {
  color: #505050;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}
.customization .label b {
  color: #21201f;
  font-weight: 600;
}
