.foilTag {
  position: relative;
}
.foilTag .foilBackground {
  border-radius: 4px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.foilTag.gold .foilBackground {
  background-color: #d0b554;
}
.foilTag.copper .foilBackground {
  background-color: #dda99a;
}
.foilTag.silver .foilBackground {
  background-color: #c0bebe;
}
.foilTag .foilText {
  position: relative;
}
