.toggleList {
  display: flex;
  min-width: 100%;
  flex-wrap: wrap;
  margin: -6px -8px;
}
.item {
  padding: 6px 8px;
}
.narrow {
  padding: -6px -5px;
}
@media screen and (max-width: 768px) {
  .toggleList {
    justify-content: space-between;
  }
  .item {
    flex: 0 0 50%;
  }
}
.toggleList.pill {
  margin: -8px;
}
.toggleList.pill .item {
  padding: 8px;
}
.toggleList.iconOnly {
  margin: -8px;
}
.toggleList.iconOnly .item {
  padding: 8px;
}
@media screen and (max-width: 768px) {
  .toggleList.iconOnly {
    justify-content: flex-start;
  }
  .toggleList.iconOnly .item {
    flex: 0 0 auto;
  }
}
.toggleList.iconOnlyRound {
  margin: -2px;
}
.toggleList.iconOnlyRound .item {
  padding: 2px;
}
@media screen and (max-width: 768px) {
  .toggleList.iconOnlyRound {
    justify-content: flex-start;
  }
  .toggleList.iconOnlyRound .item {
    flex: 0 0 auto;
  }
}
