.wrapper {
  padding-top: 16px;
}
@media screen and (min-width: 992px) {
  .toggleList {
    margin: -6px !important;
  }
}
.item {
  flex: 0 0 50%;
  padding: 6px !important;
}
@media screen and (min-width: 992px) {
  .item {
    flex: 0 0 25%;
  }
}
.pdpToggleButton {
  min-width: 100% !important;
}
